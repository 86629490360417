import React, { forwardRef, useState } from 'react'
import { Box, Button, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { apiCreateBroker, apiGetBroker } from '../../store';
import { useDispatch } from 'react-redux';
import { showToast } from '../../utils'

const CreateBroker = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        name: '',
        country: '',
        location: '',
        bank_details: ''
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const response = await dispatch(apiCreateBroker(state))
        if (response.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetBroker())
            handleClose()
            showToast('success', 'Endpoint created successfully')
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.error.message)
        } else {
            showToast('error', 'An error occurred')
        }
    }
    return (

        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Create broker</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        id="nameLabel"
                    >
                        Name
                    </InputLabel>
                    <OutlinedInput
                        id="name"
                        label="Name"
                        value={state.name}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        id="countryLabel"
                    >
                        Country
                    </InputLabel>
                    <OutlinedInput
                        id="country"
                        label="Country"
                        value={state.country}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        id="locationLabel"
                    >
                        Location
                    </InputLabel>
                    <OutlinedInput
                        id="location"
                        label="Location"
                        value={state.location}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        id="bankDetailsLabel"
                    >
                        Bank Details
                    </InputLabel>
                    <OutlinedInput
                        id="bank_details"
                        label="Bank Details"
                        value={state.bank_details}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    )
})

export default CreateBroker