import { useState } from "react";

import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, IconButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { sidebar_options } from "../data";
import { PATH } from "../resources";

import { apiLogout } from "../store";
import { showToast } from "../utils";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";

const Item = ({ title, to, icon }) => {
    const location = useLocation();

    return (
        <MenuItem
            active={location.pathname === to}
            icon={icon}
        >
            <Typography variant="body2">{title}</Typography>
            <Link to={to} />
        </MenuItem>
    );
};

const Sidebar = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem("isCollapsed") === "true"
    );

    const handleCollapse = () => {
        localStorage.setItem("isCollapsed", !isCollapsed);
        setIsCollapsed(!isCollapsed);
    };

    const handleLogout = async () => {
        if (window.confirm("Are you sure you want to log out?")) {
            const resp = await dispatch(apiLogout());
            if (resp.meta.requestStatus === "fulfilled") {
                localStorage.removeItem("loginState");
                window.location.reload();
            } else if (resp.meta.requestStatus === "rejected") {
                showToast("error", resp.payload);
            } else {
                showToast("error", "Failed to logout");
            }
        }
    }


    return (
        <ProSidebar collapsed={isCollapsed}>
            <SidebarHeader>
                <Menu>
                    <MenuItem
                        icon={
                            isCollapsed && <MenuOutlinedIcon onClick={handleCollapse} />
                        }
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    variant="body1"
                                >
                                    ENERGY PORTFOLIO
                                </Typography>
                                <IconButton onClick={handleCollapse}>
                                    <MenuOutlinedIcon sx={{ color: "white" }} />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>
                </Menu>
            </SidebarHeader>
            <Menu>
                {sidebar_options.map((options, index) => {
                    return (
                        <SubMenu key={index} title={options.header} icon={options.icon}>
                            {options.childrens.map((item, index) => {
                                if (item.isAdminRequired && !user.admin) return null;
                                return (
                                    <Item
                                        key={index}
                                        title={item.label}
                                        to={item.link}
                                        icon={item.icon}
                                    />
                                );
                            })}
                        </SubMenu>
                    )
                })}
            </Menu>
            <SidebarFooter style={{ marginTop: "auto" }}>
                <Menu>
                    <SubMenu title={user.fullname} icon={<PersonIcon />}>
                        <Item
                            title="Settings"
                            to={PATH.SETTINGS}
                            icon={<SettingsOutlinedIcon />}
                        />
                        <Item
                            title="Clients"
                            to={PATH.CLIENTS}
                            icon={<GroupsOutlinedIcon />}
                        />
                        <Item
                            title="Users"
                            to={PATH.USERS}
                            icon={<PeopleOutlinedIcon />}
                        />
                    </SubMenu>
                    <MenuItem
                        icon={<LogoutIcon />}
                        onClick={handleLogout}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    );
};

export default Sidebar;
