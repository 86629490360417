import React, { forwardRef, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Button, Input } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { apiCreatePortfolio, apiGetPortfolio, apiUploadProfile } from '../../store';
import { showToast } from '../../utils';
import { useDispatch } from 'react-redux';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const CreatePortfolio = forwardRef(({ Users, handleClose }, ref) => {
    const dispatch = useDispatch()

    const initialState = {
        name: '',
        currency: '',
        country: '',
        owner: '',
        location: '',
        commodity: '',
        usersuuid: [],
    }

    const [state, setState] = useState(initialState);
    const [users, setUsers] = useState([]);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setState({
            ...state,
            [event.target.name]: value,
        });
    };

    const handleChangeMultiple = (event) => {
        const { target: { value }, } = event;
        setUsers(typeof value === 'string' ? value.split(',') : value)
        let users = value.map((user) => {
            return Users.find((u) => u.uuid === user)
        })
        setState({ ...state, users: users })
    }

    const [profileState, setProfileState] = useState({
        portfolio_name: '',
        data_type: 'demand1',
        myFile: null,
        portfolio_uuid: ''
    })

    const handleChangeFile = (event) => {
        const { target: { files }, } = event;
        setProfileState({
            ...profileState,
            myFile: files[0]
        });
    };

    const handleUploadProfile = async (portfolio_uuid, portfolio_name) => {
        const formData = new FormData()
        formData.append('portfolio_name', portfolio_name)
        formData.append('data_type', profileState.data_type)
        formData.append('myFile', profileState.myFile)
        formData.append('portfolio_uuid', portfolio_uuid)

        const resp = await dispatch(apiUploadProfile(formData))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(apiGetPortfolio())
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = { ...state, usersuuid: state.usersuuid.map((user) => user.uuid) }
        const resp = await dispatch(apiCreatePortfolio(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            const portfolio_uuid = resp.payload.data.uuid
            const portfolio_name = resp.payload.data.name
            handleUploadProfile(portfolio_uuid, portfolio_name)
            dispatch(apiGetPortfolio())
            handleClose()
            setProfileState({
                portfolio_name: '',
                data_type: 'demand1',
                myFile: null,
                portfolio_uuid: ''
            })
            showToast('success', 'Portfolio created successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Create Portfolio</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <Box className='flex gap-3'>
                    <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                        <InputLabel
                            id="nameLabel"
                        >
                            Name
                        </InputLabel>
                        <OutlinedInput
                            id="name"
                            label="Name"
                            name='name'
                            value={state.name}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                        <InputLabel
                            id="currencyLabel"
                        >
                            Currency
                        </InputLabel>
                        <OutlinedInput
                            id="currency"
                            label="Currency"
                            name='currency'
                            value={state.currency}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                </Box>
                <Box className='flex gap-3'>
                    <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                        <InputLabel
                            id="countryLabel"
                        >
                            Country
                        </InputLabel>
                        <OutlinedInput
                            id="country"
                            label="Country"
                            name='country'
                            value={state.country}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                        <InputLabel
                            id="ownerLabel"
                        >
                            Owner
                        </InputLabel>
                        <OutlinedInput
                            id="owner"
                            label="Owner"
                            name='owner'
                            value={state.owner}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                </Box>
                <Box className='flex gap-3'>
                    <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                        <InputLabel
                            id="locationLabel"
                        >
                            Location
                        </InputLabel>
                        <OutlinedInput
                            id="location"
                            label="Location"
                            name='location'
                            value={state.location}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                        <InputLabel
                            id="commodityLabel"
                        >
                            Commodity
                        </InputLabel>
                        <OutlinedInput
                            id="commodity"
                            label="Commodity"
                            name='commodity'
                            value={state.commodity}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                </Box>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5' }}>Visible to Users</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={users}
                        onChange={handleChangeMultiple}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={MenuProps}
                    >
                        {Users.map((user) => (
                            <MenuItem key={user.uuid} value={user.uuid} >
                                {user.fullname}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* file input */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5', display: 'none' }}>Profile CSV</InputLabel>
                    <Input
                        type='file'
                        id="profile"
                        name='fd'
                        onChange={handleChangeFile}
                    />
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    );
})

export default CreatePortfolio