import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { ReactTable } from '../../components';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'

const YearlyTotal = () => {
    const { demandStatistics } = useSelector((state) => state.statistics);

    // find same year in demandStatistics.Data and add the same year data
    const yearTotal = useMemo(() => {
        const yearTotal = [];
        demandStatistics?.Data?.forEach((item) => {
            const year = item.Year;
            const index = yearTotal.findIndex((x) => x.Year === year);
            if (index === -1) {
                yearTotal.push({
                    Year: year,
                    TotalSupplyVol: item.SupplyTotal,
                    TotalDemandVol: item.Total,
                    TotalSupplyAmount: item.SupplyPriceTotal,
                    TotalRemaining: item.RemainingVolume,
                    TotalAmount: item.SupplyPriceTotal + item.RemainingVolume
                });
            } else {
                yearTotal[index].TotalSupplyVol += item.SupplyTotal;
                yearTotal[index].TotalDemandVol += item.Total;
                yearTotal[index].TotalSupplyAmount += item.SupplyPriceTotal;
                yearTotal[index].TotalRemaining += item.RemainingVolume;
                yearTotal[index].TotalAmount += item.SupplyPriceTotal + item.RemainingVolume;
            }
        });
        return yearTotal;
    }, [demandStatistics]);

    // supply statistics columns
    const yearTotalColumns = useMemo(
        () => [
            {
                header: "Year",
                id: "Year",
                accessorFn: ({ Year }) => Year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Total Supply Vol [MWh]",
                id: "TotalSupplyVol",
                accessorFn: ({ TotalSupplyVol }) => TotalSupplyVol?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")  || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Total Demand Vol (MWh)",
                id: "TotalDemandVol",
                accessorFn: ({ TotalDemandVol }) => TotalDemandVol?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")  || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Total Supply Amount [Eur]",
                id: "TotalSupplyAmount",
                accessorFn: ({ TotalSupplyAmount }) => TotalSupplyAmount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")  || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Total Remaining Amount [Eur]",
                id: "TotalRemaining",
                accessorFn: ({ TotalRemaining }) => TotalRemaining?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")  || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Total Amount [Eur]",
                id: "TotalAmount",
                accessorFn: ({ TotalAmount }) => TotalAmount?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")  || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            }
        ], []
    );

    return (
        <ReactTable
            headerText={'Yearly Total'}
            columns={yearTotalColumns}
            data={yearTotal || []}
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                    </div>
                )
            }
        />
    )
}

export default YearlyTotal