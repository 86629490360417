import { PATH } from "../resources";
import DashboardOutlineIcon from '@mui/icons-material/DashboardOutlined';
import TuneOutlineIcon from '@mui/icons-material/TuneOutlined';
import DescriptionOutlineIcon from '@mui/icons-material/DescriptionOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import StoreIcon from '@mui/icons-material/Store';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import FenceOutlinedIcon from '@mui/icons-material/FenceOutlined';

export let dashboard = {
    header: 'Main',
    icon: <HomeIcon size={18} />,
    childrens: [
        {
            label: 'Dashboard',
            link: PATH.DASHBOARD,
            icon: <DashboardOutlineIcon size={18} />,
        },
        {
            label: 'Hedges',
            link: PATH.HEDGES,
            icon: <TuneOutlineIcon size={18} />,
        }
    ]
};

export let market = {
    header: 'Markets',
    icon: <StoreIcon size={18} />,
    childrens: [
        {
            label: 'Outlook',
            link: PATH.OUTLOOK,
            icon: <DescriptionOutlineIcon size={18} />,
        },
        {
            label: 'Analysis',
            link: PATH.ANALYSIS,
            icon: <EqualizerOutlinedIcon size={18} />,
        }
    ]
};

export let forecasting = {
    header: 'Forecasting',
    icon: <BubbleChartIcon size={18} />,
    childrens: [
        {
            label: 'Auto Reporting',
            link: PATH.AUTO_REPORTING,
            icon: <InsightsOutlinedIcon size={18} />,
        }
    ]
};

export let entry_system = {
    header: 'Entry System',
    icon: <SettingsSuggestIcon size={18} />,
    childrens: [
        {
            label: 'Search',
            link: PATH.SEARCH,
            icon: <SearchOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Forward',
            link: PATH.FORWARD,
            icon: <FastForwardOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Hedge',
            link: PATH.HEDGE,
            icon: <FenceOutlinedIcon size={18} />,
            isAdminRequired: false
        },
        {
            label: 'Hedge Transfer',
            link: PATH.HEDGE_TRANSFER,
            icon: <LocalShippingOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Options',
            link: PATH.OPTIONS,
            icon: <PlaylistAddCheckOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Counterparty',
            link: PATH.COUNTERPARTY,
            icon: <ModelTrainingOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Portfolio',
            link: PATH.PORTFOLIO,
            icon: <HourglassBottomOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Brokers',
            link: PATH.BROKERS,
            icon: <CloseFullscreenOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Exchanges',
            link: PATH.EXCHANGES,
            icon: <CachedOutlinedIcon size={18} />,
            isAdminRequired: true
        },
        {
            label: 'Endpoints',
            link: PATH.ENDPOINTS,
            icon: <AccountTreeOutlinedIcon size={18} />,
            isAdminRequired: true
        },
    ]
};

export let sidebar_options = [
    dashboard,
    market,
    forecasting,
    entry_system,
]