import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Button,
    Grid,
    Box,
    Typography,
    IconButton,
    OutlinedInput,
    InputAdornment,
    FormControl,
    InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiLogin } from "../store";
import { PATH } from "../resources";
import { useAuth } from "../hooks";
// import Login_Background from "../assets/images/login-bg.png";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import KeyIcon from "@mui/icons-material/Key";
import MailIcon from "@mui/icons-material/Mail";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from '@mui/material/CircularProgress';

export default function Login() {
    const dispatch = useDispatch();
    const [showPass, setShowPass] = useState(false);
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const { isLoginLoading } = useSelector((state) => state.user);

    const [state, setState] = useState({
        username: "",
        password: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await dispatch(apiLogin(state));
        if (response.payload.status === 200) {
            setAuth({
                session: true,
            });
            localStorage.setItem("loginState", "true");
            navigate(PATH.DASHBOARD);
        } else if (response.status === 401) {
            alert("Invalid username or password");
        } else {
            alert("Something went wrong");
        }
    };

    return (
        <Box sx={styles.root} >
            {/* <Box sx={{ mb: 3 }}>
                <Typography variant="h4" component="h1" sx={{ color: "#F50057" }}>
                    ENERGY PORTFOLIO
                </Typography>
            </Box> */}
            <Box sx={styles.container}>
                <Box sx={styles.icon}>
                    <LockOutlinedIcon sx={{ fontSize: "30px" }} />
                </Box>
                <Typography component="h1" variant="h5">
                    Log In
                </Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel
                                    htmlFor="login-email"
                                >
                                    Email
                                </InputLabel>
                                <OutlinedInput
                                    label="Email"
                                    value={state.username}
                                    autoComplete="email"
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            username: e.target.value,
                                        })
                                    }
                                    id="login-email"
                                    required
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true} variant="outlined">
                                <InputLabel htmlFor="login-password">
                                    Password
                                </InputLabel>
                                <OutlinedInput
                                    label="Password"
                                    value={state.password}
                                    autoComplete="current-password"
                                    onChange={(e) =>
                                        setState({
                                            ...state,
                                            password: e.target.value,
                                        })
                                    }
                                    id="login-password"
                                    required
                                    type={showPass ? "text" : "password"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() =>
                                                    setShowPass(!showPass)
                                                }
                                                onMouseDown={(e) =>
                                                    e.preventDefault()
                                                }
                                                edge="end"
                                            >
                                                {showPass ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <KeyIcon />
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={styles.button}
                    >
                        {isLoginLoading ? <CircularProgress size={24} color="inherit" /> : "Log In"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: "#f5f5f5",
        // backgroundImage: `url(${Login_Background})`,
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        // backgroundRepeat: 'no-repeat',
    },
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "400px",
        border: "1px solid #ccc",
        padding: "40px",
        borderRadius: "3px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
    },
    icon: {
        color: "#fff",
        backgroundColor: "#F50057",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "10px",
    },
    button: {
        fontWeight: "bold",
        marginTop: "20px",
        backgroundColor: "#3F51B5",
        height: "50px",
        '&:active': {
            backgroundColor: '#3F51B5'
        },
        '&:hover': {
            backgroundColor: '#3F51B5'
        }
    }
}