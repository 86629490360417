import { Box } from '@mui/material'
import React from 'react'
import SearchInput from './SearchInput'
import SearchResult from './SearchResult'

const Search = () => {
    return (
        <Box display={'flex'} gap={2} flexDirection={'column'}>
            <SearchInput />
            <SearchResult />
        </Box>
    )
}

export default Search