import React, { useMemo } from 'react'
import { ReactTable } from '../../components';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'
import { useSelector } from 'react-redux';

const OverallStatistics = ({ state }) => {
    const { demandStatistics } = useSelector((state) => state.statistics);

    const month_to_int = {
        "January": 1,
        "February": 2,
        "March": 3,
        "April": 4,
        "May": 5,
        "June": 6,
        "July": 7,
        "August": 8,
        "September": 9,
        "October": 10,
        "November": 11,
        "December": 12
    }

    const overallStatisticsData = useMemo(() => {
        if (state.fyear && state.tyear) {
            let fyear = parseInt(state.fyear)
            let tyear = parseInt(state.tyear)
            let fmonth = month_to_int[state.fmonth]
            let tmonth = month_to_int[state.tmonth]
            const data = demandStatistics?.Data?.filter((item) => {
                return (item.Year >= fyear && item.Year <= tyear && item.Month >= fmonth && item.Month <= tmonth)
            })
            return data
        }
        return demandStatistics?.Data
    }, [demandStatistics, state.fyear, state.tyear, state.fmonth, state.tmonth])

    const overallStatisticsColumn = useMemo(
        () => [
            {
                header: "Month",
                id: "MonthStr",
                accessorFn: ({ MonthStr }) => MonthStr || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Year",
                id: "Year",
                accessorFn: ({ Year }) => Year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Total Vol [MWh]",
                id: "Total",
                accessorFn: ({ Total }) => Total?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Average Price [Eur/MWh]",
                id: "AveragePrice",
                Cell: ({ row }) => <>{((row?.original.SupplyPriceTotal + (row?.original?.RemainingVolume * row?.original?.AskPrice)) / row?.original?.Total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>,
            },
            {
                header: "Total Amount [Eur]",
                id: "TotalAmount",
                Cell: ({ row }) => <>{(row?.original?.SupplyPriceTotal + (row?.original?.RemainingVolume * row?.original?.AskPrice)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</>,
            },
            {
                header: "Effective Vol [MWh]",
                id: "MEVTotal",
                accessorFn: ({ MEVTotal }) => MEVTotal?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            }
        ], []
    );

    return (
        <ReactTable
            headerText={'Overall Statistics'}
            columns={overallStatisticsColumn}
            data={overallStatisticsData || []}
            renderToolbarInternalActions={
                ({ table }) => (
                    <div onMouseDown={(e) => e.stopPropagation()}>
                        <MRT_ToggleGlobalFilterButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleDensePaddingButton table={table} />
                    </div>
                )
            }
        />
    )
}

export default OverallStatistics