import React, { forwardRef } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';

const ForwardDetails = forwardRef(({ details, handleClose }, ref) => {
    return (
        <Box className="modal-container" >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Transaction Details</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <Box className="modal-body" display={'flex'} gap={5} flexDirection={'column'}>
                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} columnGap={5} rowGap={2}>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Transaction Type: </Typography>
                        <Typography variant={'body2'}>{details?.transaction_type}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Trade Type: </Typography>
                        <Typography variant={'body2'}>{details?.trade_type}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Trader: </Typography>
                        <Typography variant={'body2'}>{details?.trader?.fullname}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Portfolio: </Typography>
                        <Typography variant={'body2'}>{details?.portfolio?.name}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Counterparty: </Typography>
                        <Typography variant={'body2'}>{details?.counterparty?.name}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Reference: </Typography>
                        <Typography variant={'body2'}>{details?.reference}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>UTI/UUID: </Typography>
                        <Typography variant={'body2'}>{details?.uuid}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Exchange: </Typography>
                        <Typography variant={'body2'}>{details?.exchange?.name}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Exchange UUID: </Typography>
                        <Typography variant={'body2'}>{details?.exchange?.uuid}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Status: </Typography>
                        <Typography variant={'body2'}>{details?.status}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Price Type: </Typography>
                        <Typography variant={'body2'}>{details?.price_type}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Endpoint: </Typography>
                        <Typography variant={'body2'}>{details?.endpoint?.name}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Payment Frequency: </Typography>
                        <Typography variant={'body2'}>{details?.payment_frequency}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Delivery Period: </Typography>
                        <Typography variant={'body2'}>{details?.delivery_period_sym} {details?.delivery_period_month} {details?.delivery_period_year}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Currency: </Typography>
                        <Typography variant={'body2'}>{details?.currency}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Price: </Typography>
                        <Typography variant={'body2'}>{details?.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Volume: </Typography>
                        <Typography variant={'body2'}>{details?.volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                    </Box>
                    <Box display={'flex'} gap={3} alignItems={'center'}>
                        <Typography width={140} variant={'body2'} fontWeight={'bold'}>Capacity: </Typography>
                        <Typography variant={'body2'}>{details?.capacity.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                    </Box>
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <Typography variant={'body2'} fontWeight={'bold'}>Comments and Conditions: </Typography>
                    <Typography variant={'body2'}>{details?.comment}</Typography>
                </Box>
            </Box>
            <Box className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Box>
        </Box>
    )
})

export default ForwardDetails