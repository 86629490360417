const delivery_period_options = [
    { value: 'DA', label: 'DA' },
    { value: 'BOM', label: 'BOM' },
    { value: 'MONTH', label: 'MONTH' },
    { value: 'SUMMER', label: 'SUMMER' },
    { value: 'WINTER', label: 'WINTER' },
    { value: 'Q1', label: 'Q1' },
    { value: 'Q2', label: 'Q2' },
    { value: 'Q3', label: 'Q3' },
    { value: 'Q4', label: 'Q4' },
    { value: 'CAL', label: 'CAL' },
    { value: 'GY', label: 'GY' },
    { value: 'MANUAL', label: 'MANUAL' },
]

const months_options = [
    { value: 'JAN', label: 'JAN' },
    { value: 'FEB', label: 'FEB' },
    { value: 'MAR', label: 'MAR' },
    { value: 'APR', label: 'APR' },
    { value: 'MAY', label: 'MAY' },
    { value: 'JUN', label: 'JUN' },
    { value: 'JUL', label: 'JUL' },
    { value: 'AUG', label: 'AUG' },
    { value: 'SEP', label: 'SEP' },
    { value: 'OCT', label: 'OCT' },
    { value: 'NOV', label: 'NOV' },
    { value: 'DEC', label: 'DEC' },
]

// loop year 2020 to 2054
const years_options = Array.from({ length: 35 }, (v, k) => k + 2020).map((year) => {
    return { value: year, label: year }
});

const price_type_options = [
    { value: 'Fixed', label: 'Fixed' },
    { value: 'DA Index', label: 'DA Index' },
    { value: 'MA Index', label: 'MA Index' },
]

const edit_portfolio_csv_type_options = [
    { value: 'demand1', label: 'Demand Main' },
    { value: 'demand2', label: 'demand 2' },
    { value: 'demand3', label: 'demand 3' },
    { value: 'demand4', label: 'demand 4' },
    { value: 'demand5', label: 'demand 5' },
]

const forecasting_report_product = [
    { value: 'TTF-DA', label: 'TTF-DA' },
    { value: 'TTF-FIRSTMONTH', label: 'TTF-FIRSTMONTH' },
    { value: 'TTF-FIRSTQUARTER', label: 'TTF-FIRSTQUARTER' },
    { value: 'TTF-FIRSTWINTER', label: 'TTF-FIRSTWINTER' },
    { value: 'TTF-FIRSTSUMMER', label: 'TTF-FIRSTSUMMER' },
    { value: 'TTF-FIRSTCAL', label: 'TTF-FIRSTCAL' },
    { value: 'TTF-SECONDCAL', label: 'TTF-SECONDCAL' },
    { value: 'THE-FIRSTQUARTER', label: 'THE-FIRSTQUARTER' },
    { value: 'THE-FIRSTWINTER', label: 'THE-FIRSTWINTER' },
    { value: 'THE-FIRSTSUMMER', label: 'THE-FIRSTSUMMER' },
    { value: 'THE-FIRSTCAL', label: 'THE-FIRSTCAL' },
    { value: 'THE-SECONDCAL', label: 'THE-SECONDCAL' },
    { value: 'THE-THIRDCAL', label: 'THE-THIRDCAL' },
    { value: 'GERMANPOWER-FIRSTCAL', label: 'GERMANPOWER-FIRSTCAL' },
    { value: 'GERMANPOWER-SECONDCAL', label: 'GERMANPOWER-SECONDCAL' },
    { value: 'GERMANPOWER-THIRDCAL', label: 'GERMANPOWER-THIRDCAL' },
    { value: 'BRENT-FIRSTMONTH', label: 'BRENT-FIRSTMONTH' },
]

export {
    delivery_period_options,
    months_options,
    years_options,
    price_type_options,
    edit_portfolio_csv_type_options,
    forecasting_report_product
}