import React, { useMemo, useState } from 'react'
import { ReactTable } from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { MenuItem, Modal } from '@mui/material';
import EditForward from '../forward/EditForward';
import ForwardDetails from '../forward/ForwardDetails';
import { apiDeleteForward, deleteForward } from '../../store';
import { showToast } from '../../utils';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'


const SearchResult = () => {
    const dispatch = useDispatch()
    const { searchResults, isGetSearchLoading } = useSelector((state) => state.search)

    const [selectedRow, setSelectedRow] = useState({})

    const [openEditModal, setOpenEditModal] = useState(false)
    const handleOpenEditModal = (details) => {
        setOpenEditModal(true)
        setSelectedRow(details)
    }
    const handleCloseEditModal = () => { setOpenEditModal(false) }

    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const handleOpenDetailsModal = (details) => {
        setOpenDetailsModal(true)
        setSelectedRow(details)
    }
    const handleCloseDetailsModal = () => { setOpenDetailsModal(false) }

    const handleDelete = async (forward) => {
        const resp = await dispatch(apiDeleteForward(forward))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(deleteForward(forward))
            showToast('success', 'Forward deleted successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "UUID",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Reference",
                id: "reference",
                accessorFn: ({ reference }) => reference || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Type",
                id: "transaction_type",
                accessorFn: ({ transaction_type }) => transaction_type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Trade Date",
                id: "trade_date_str",
                accessorFn: ({ trade_date_str }) => trade_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Contract",
                id: "contract",
                Cell: ({ row }) => (row?.original?.delivery_period_sym + " " + row?.original?.delivery_period_year + " " + row?.original?.delivery_period_month),
                size: 50
            },
            {
                header: "Start Date",
                id: "start_date_str",
                accessorFn: ({ start_date_str }) => start_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "End Date",
                id: "end_date_str",
                accessorFn: ({ end_date_str }) => end_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Capacity (MW)",
                id: "capacity",
                accessorFn: ({ capacity }) => capacity?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume (MWh)",
                id: "volume",
                accessorFn: ({ volume }) => volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Fixed Price (Eur/MWh)",
                id: "price",
                Cell: ({ row }) => row?.original?.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                size: 50
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key='edit' onClick={() => handleOpenEditModal(row.original)}>
                        <EditIcon sx={{ paddingRight: "10px" }} /> Edit
                    </MenuItem>,
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original)}>
                        <DeleteIcon sx={{ paddingRight: "10px" }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <EditForward
                    handleClose={handleCloseEditModal}
                    details={selectedRow}
                />
            </Modal>
            <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
                <ForwardDetails
                    handleClose={handleCloseDetailsModal}
                    details={selectedRow}
                />
            </Modal>
            <ReactTable
                headerText={'Transactions'}
                columns={columns}
                data={searchResults || []}
                actions={actionsMenu}
                handleRowClick={handleOpenDetailsModal}
                isLoading={isGetSearchLoading}
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                        </div>
                    )
                }
            />
        </>
    )
}

export default SearchResult