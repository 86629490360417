import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import React, { forwardRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { apiUpdateForward } from '../../store';
import { useDispatch } from 'react-redux';
import { showToast } from '../../utils';

const EditForward = forwardRef(({ details, handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        transaction_type: details?.transaction_type || '',
        status: details?.status || '',
        comments: details?.comments || '',
        volume: details?.volume || '',
        price: details?.price || ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = {
            uuid: details.uuid,
            account_uuid: details.account_uuid,
            status: state.status,
            price: state.price,
            volume: state.volume,
            transaction_type: state.transaction_type,
            comment: state.comments
        }
        const resp = await dispatch(apiUpdateForward(data))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Transaction Updated Successfully')
            handleClose()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp?.payload)
        } else {
            showToast('error', 'An error occurred')
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Edit Transaction</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <Box className="modal-body" display={'flex'} gap={2} flexDirection={'column'}>
                <Box className="modal-body" display={'flex'} gap={5} flexDirection={'column'}>
                    <Box display={'grid'} gridTemplateColumns={'repeat(4 , 1fr)'} columnGap={5} rowGap={2}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>UTI/UUID: </Typography>
                            <Typography variant={'body2'}>{details?.uuid}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Trade Type: </Typography>
                            <Typography variant={'body2'}>{details?.trade_type}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Trader: </Typography>
                            <Typography variant={'body2'}>{details?.trader?.fullname}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Portfolio: </Typography>
                            <Typography variant={'body2'}>{details?.portfolio?.name}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Counterparty: </Typography>
                            <Typography variant={'body2'}>{details?.counterparty?.name}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Reference: </Typography>
                            <Typography variant={'body2'}>{details?.reference}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Exchange UUID: </Typography>
                            <Typography variant={'body2'}>{details?.exchange?.uuid}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Exchange: </Typography>
                            <Typography variant={'body2'}>{details?.exchange?.name}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Capacity: </Typography>
                            <Typography variant={'body2'}>{details?.capacity.toFixed(2)}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Price Type: </Typography>
                            <Typography variant={'body2'}>{details?.price_type}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Endpoint: </Typography>
                            <Typography variant={'body2'}>{details?.endpoint?.name}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Payment Frequency: </Typography>
                            <Typography variant={'body2'}>{details?.payment_frequency}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Delivery Period: </Typography>
                            <Typography variant={'body2'}>{details?.transaction_type}</Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography width={140} variant={'body2'} fontWeight={'bold'}>Currency: </Typography>
                            <Typography variant={'body2'}>{details?.currency}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display={'grid'} gridTemplateColumns={'repeat(4 , 1fr)'} columnGap={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select22"
                            label="Transaction Type"
                            name='transaction_type'
                            value={state.transaction_type}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={'buy'}>Buy</MenuItem>
                            <MenuItem value={'sell'}>Sell</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select22"
                            label="Status"
                            name='status'
                            value={state.status}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={'New'}>New</MenuItem>
                            <MenuItem value={'Pending'}>Pending</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="commentLabel"
                            sx={{ bgcolor: '#F5F5F5' }}
                        >
                            Price
                        </InputLabel>
                        <OutlinedInput
                            id="price"
                            label="price"
                            name='price'
                            value={state.price}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="commentLabel"
                            sx={{ bgcolor: '#F5F5F5' }}
                        >
                            Volume
                        </InputLabel>
                        <OutlinedInput
                            id="volume"
                            label="volume"
                            name='volume'
                            type='number'
                            inputProps={{ step: 0.01 }}
                            value={state.volume}
                            onChange={handleChange}
                            required
                        />
                    </FormControl>
                </Box>
                <FormControl fullWidth={true} variant="outlined">
                    <InputLabel
                        id="commentLabel"
                        sx={{ bgcolor: '#F5F5F5' }}
                    >
                        Comments and Conditions
                    </InputLabel>
                    <OutlinedInput
                        id="comments"
                        label="Comments"
                        name='comments'
                        value={state.comments}
                        onChange={handleChange}
                    />
                </FormControl>
            </Box>
            <Box className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </Box>
        </Box>
    )
})

export default EditForward