import React, { useMemo, useState } from 'react'
import { ReactTable } from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material';
import EditForward from '../forward/EditForward';
import ForwardDetails from '../forward/ForwardDetails';
import { apiDeleteForward, deleteForward } from '../../store';
import { showToast } from '../../utils';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
} from 'material-react-table';
import SearchIcon from '@mui/icons-material/Search';

const Transaction = ({ monthList, yearList, state, handleChange, handleSubmit }) => {
    const dispatch = useDispatch()

    const { transactions, loading } = useSelector((state) => state.transaction)

    const [selectedRow, setSelectedRow] = useState({})

    const [openEditModal, setOpenEditModal] = useState(false)
    const handleOpenEditModal = (details) => {
        setOpenEditModal(true)
        setSelectedRow(details)
    }
    const handleCloseEditModal = () => { setOpenEditModal(false) }

    const [openDetailsModal, setOpenDetailsModal] = useState(false)
    const handleOpenDetailsModal = (details) => {
        setOpenDetailsModal(true)
        setSelectedRow(details)
    }
    const handleCloseDetailsModal = () => { setOpenDetailsModal(false) }

    const handleDelete = async (forward) => {
        const resp = await dispatch(apiDeleteForward(forward))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            dispatch(deleteForward(forward))
            showToast('success', 'Forward deleted successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "UUID",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 'min-content'
            },
            {
                header: "Reference",
                id: "reference",
                accessorFn: ({ reference }) => reference || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 'min-content'
            },
            {
                header: "Type",
                id: "transaction_type",
                accessorFn: ({ transaction_type }) => transaction_type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Trade Date",
                id: "trade_date",
                Cell: ({ row }) => row?.original?.trade_date_str,
                size: 50
            },
            {
                header: "Contract",
                id: "contract",
                Cell: ({ row }) => (row?.original?.delivery_period_sym + " " + row?.original?.delivery_period_year + " " + row?.original?.delivery_period_month),
                size: 50
            },
            {
                header: "Start Date",
                id: "start_date_str",
                accessorFn: ({ start_date_str }) => start_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "End Date",
                id: "end_date_str",
                accessorFn: ({ end_date_str }) => end_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Capacity (MW)",
                id: "capacity",
                accessorFn: ({ capacity }) => capacity?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume (MWh)",
                id: "volume_str",
                accessorFn: ({ volume_str }) => volume_str.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Fixed Price (Eur/MWh)",
                id: "price",
                Cell: ({ row }) => row?.original?.price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                size: 50
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key='edit' onClick={() => handleOpenEditModal(row.original)}>
                        <EditIcon sx={{ paddingRight: "10px" }} /> Edit
                    </MenuItem>,
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original)}>
                        <DeleteIcon sx={{ paddingRight: "10px" }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <EditForward
                    handleClose={handleCloseEditModal}
                    details={selectedRow}
                />
            </Modal>
            <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
                <ForwardDetails
                    handleClose={handleCloseDetailsModal}
                    details={selectedRow}
                />
            </Modal>
            <ReactTable
                headerText={'Transactions'}
                columns={columns}
                data={transactions || []}
                actions={actionsMenu}
                handleRowClick={handleOpenDetailsModal}
                isLoading={loading}
                renderToolbarInternalActions={
                    ({ table }) => (
                        <Box onMouseDown={(e) => e.stopPropagation()} display='flex'>
                            <Box component={'form'} onSubmit={handleSubmit} display={'flex'}>
                                <Box display={'flex'} alignItems={'center'} gap={1} mr={2}>
                                    <Typography fontSize={'small'} variant='caption'>From: </Typography>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        sx={{
                                            minWidth: 'min-content',
                                        }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                                fontSize: '12px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            Month
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Month"
                                            name='fmonth'
                                            value={state.fmonth}
                                            onChange={handleChange}
                                            required
                                            sx={{
                                                fontSize: '12px',
                                                minWidth: '96px',
                                                maxWidth: 'min-content'
                                            }}
                                        >
                                            {monthList.map((month, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={month}
                                                    sx={{
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        sx={{
                                            minWidth: 'min-content',
                                        }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                                fontSize: '12px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            Year
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Year"
                                            name='fyear'
                                            value={state.fyear}
                                            onChange={handleChange}
                                            required
                                            sx={{
                                                fontSize: '12px',
                                                minWidth: '96px',
                                                maxWidth: 'min-content'
                                            }}
                                        >
                                            {yearList.map((year, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={year}
                                                    sx={{
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display={'flex'} alignItems={'center'} gap={1}>
                                    <Typography fontSize={'small'} variant='caption'>To: </Typography>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        sx={{
                                            minWidth: 'min-content',
                                        }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                                fontSize: '12px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            Month
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Month"
                                            name='tmonth'
                                            value={state.tmonth}
                                            onChange={handleChange}
                                            required
                                            sx={{
                                                fontSize: '12px',
                                                minWidth: '96px',
                                                maxWidth: 'min-content'
                                            }}
                                        >
                                            {monthList.map((month, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={month}
                                                    sx={{
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        fullWidth
                                        size="small"
                                        sx={{
                                            minWidth: 'min-content',
                                        }}
                                    >
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            sx={{
                                                fontSize: '12px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            Year
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Year"
                                            name='tyear'
                                            value={state.tyear}
                                            onChange={handleChange}
                                            required
                                            sx={{
                                                fontSize: '12px',
                                                minWidth: '96px',
                                                maxWidth: 'min-content'
                                            }}
                                        >
                                            {yearList.map((year, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={year}
                                                    sx={{
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Button type='submit' sx={{ border: 'none', background: 'none' }}>
                                    <SearchIcon />
                                </Button>
                            </Box>
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                        </Box>
                    )
                }
            />
        </>
    )
}

export default Transaction