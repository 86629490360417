import { useCallback, useEffect } from 'react';
import {
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation
} from 'react-router-dom';
import {
  Dashboard,
  Hedges,
  Outloook,
  Analysis,
  GeneralForcasting,
  AutoReporting,
  Search,
  Forward,
  Hedge,
  HedgeTransfer,
  Options,
  Counterparty,
  Portfolio,
  Brokers,
  Exchanges,
  Endpoints,
  Settings,
  Clients,
  Users,
  Login,
  AutoReportingResult
} from './pages';
import Layout from './layout/Layout';
import { PATH } from './resources';
import { NoAuth, RequireAuth } from './auth';
import { useDispatch } from 'react-redux';

import { apiGetPosts, apiGetProfile, apiGetClients, apiGetPortfolio, apiGetEndpoints, apiGetExchanges, apiGetBroker, apiGetUsers, apiGetCounterParty, apiGetForecasts, apiGetPendingHedges } from './store';
import { useAuth } from './hooks';
import axios from 'axios';

function App() {
    const dispatch = useDispatch();
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const implementInterceptors = useCallback(() => {
        // Add a request interceptor
        axios.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                if (error.response.status === 401) {
                    setAuth({ session: false });
                    localStorage.removeItem('loginState');
                }
                return Promise.reject(error);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        implementInterceptors();
    }, [implementInterceptors]);

  useEffect(() => {
    if (auth?.session) {
      dispatch(apiGetUsers())
      dispatch(apiGetPosts());
      dispatch(apiGetProfile());
      dispatch(apiGetClients());
      dispatch(apiGetPortfolio())
      dispatch(apiGetEndpoints())
      dispatch(apiGetExchanges())
      dispatch(apiGetBroker())
      dispatch(apiGetCounterParty())
      dispatch(apiGetForecasts())
      dispatch(apiGetPendingHedges())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

    useEffect(() => {
        // Available routes for non-authenticated users
        const noAuthOnlyRoutes = ['/login'];
        if (!noAuthOnlyRoutes.includes(location.pathname)) {
            if (!auth?.session) {
                navigate('/login');
                localStorage.setItem('loginState', false);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

  return (
    // console.log( "%cDesigned and Developed By \n %chttps://github.com/hemantchettrii ", "color:green; font-size:20px", "font-size:12px" ),
    <Routes>
      <Route element={<NoAuth />} >
        <Route path='/' element={<Navigate to={PATH.LOGIN} />} />
        <Route path={PATH.LOGIN} element={<Login />} />
      </Route>
      <Route element={<RequireAuth />} >
        <Route element={<Layout />}>
          <Route path={PATH.DASHBOARD} element={<Dashboard />} />
          <Route path={PATH.HEDGES} element={<Hedges />} />
          <Route path={PATH.OUTLOOK} element={<Outloook />} />
          <Route path={PATH.ANALYSIS} element={<Analysis />} />
          <Route path={PATH.GENERAL_FORCASTING} element={<GeneralForcasting />} />
          <Route path={PATH.AUTO_REPORTING} element={<AutoReporting />} />
          <Route path={`${PATH.AUTO_REPORTING_RESULT}/:uuid`} element={<AutoReportingResult />} />
          <Route path={PATH.SEARCH} element={<Search />} />
          <Route path={PATH.FORWARD} element={<Forward />} />
          <Route path={PATH.HEDGE} element={<Hedge />} />
          <Route path={PATH.HEDGE_TRANSFER} element={<HedgeTransfer />} />
          <Route path={PATH.OPTIONS} element={<Options />} />
          <Route path={PATH.COUNTERPARTY} element={<Counterparty />} />
          <Route path={PATH.PORTFOLIO} element={<Portfolio />} />
          <Route path={PATH.BROKERS} element={<Brokers />} />
          <Route path={PATH.EXCHANGES} element={<Exchanges />} />
          <Route path={PATH.ENDPOINTS} element={<Endpoints />} />
          <Route path={PATH.SETTINGS} element={<Settings />} />
          <Route path={PATH.CLIENTS} element={<Clients />} />
          <Route path={PATH.USERS} element={<Users />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
