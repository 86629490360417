import React, { forwardRef, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, OutlinedInput, Input } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { months_options, years_options } from '../../data';
import { useDispatch } from 'react-redux';
import { apiUploadMEV } from '../../store';
import { showToast } from '../../utils';

const UploadMEV = forwardRef(({ singlePortfolio, handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        year: '',
        month: '',
        myFile: null,
        profile: singlePortfolio.name || '',
    })

    const handleChange = (event) => {
        const { target: { value, name }, } = event;
        setState({
            ...state,
            [name]: value,
        });
    };

    const handleFileChange = (event) => {
        setState({
            ...state,
            myFile: event.target.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('year', state.year)
        formData.append('month', state.month)
        formData.append('profile', JSON.stringify(singlePortfolio))
        formData.append('myFile', state.myFile)
        const resp = await dispatch(apiUploadMEV(formData))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'MEV Data uploaded successfully')
            handleClose()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Upload MEV Data</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <Box display={'flex'} gap={3} marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Month"
                            name='year'
                            value={state.year}
                            onChange={handleChange}
                            required
                        >
                            {years_options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Month"
                            name='month'
                            value={state.month}
                            onChange={handleChange}
                            required
                        >
                            {months_options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <FormControl fullWidth={true} sx={{ marginBottom: '3px' }} disabled>
                    <InputLabel
                        id="profileLabel"
                        shrink
                        sx={{ backgroundColor: '#F5F5F5', padding: '0 5px' }}
                    >
                        Profile
                    </InputLabel>
                    <OutlinedInput
                        id="profile"
                        label="Reference"
                        name='profile'
                        value={state.profile}
                        onChange={handleChange}
                        disabled
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5', display: 'none' }}>Profile CSV</InputLabel>
                    <Input
                        type='file'
                        id="profile"
                        name='fd'
                        onChange={handleFileChange}
                    />
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    )
})

export default UploadMEV