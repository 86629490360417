import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MenuItem } from '@mui/material'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DeleteIcon from '@mui/icons-material/Delete';
import { apiGetHedges, apiDeleteHedge, deleteHedge, apiTradeTransfer } from '../../store'
import { ReactTable } from '../../components';
import { showToast } from '../../utils';

const Hedge = ({ selectedPortfolio }) => {
    const dispatch = useDispatch()
    const { hedges, isGetHedgesLoading } = useSelector(state => state.hedges)

    useEffect(() => {
        if (selectedPortfolio.name !== '' && selectedPortfolio.uuid !== '') {
            dispatch(apiGetHedges({ portfolio_name: selectedPortfolio.name, portfolio_uuid: selectedPortfolio.uuid }))
        }
    }, [selectedPortfolio])

    const handleDeleteHedge = async (hedge) => {
        if (window.confirm("Are you sure you want to delete this hedge?")) {
            const response = await dispatch(apiDeleteHedge(hedge))
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Hedge deleted successfully')
                dispatch(deleteHedge(hedge.uuid))
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response.payload)
            } else {
                showToast('error', 'Something went wrong')
            }
        }
    }

    const hanldeTransferHedge = async (hedge) => {
        if (window.confirm("Send this hedge to Admin to transfer to a real trade?")) {
            const response = await dispatch(apiTradeTransfer(hedge))
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Hedge transferred successfully')
                dispatch(deleteHedge(hedge.uuid))
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response.payload)
            } else {
                showToast('error', 'Something went wrong')
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "UUID",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Reference",
                id: "reference",
                accessorFn: ({ reference }) => reference || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Type",
                id: "transaction_type",
                accessorFn: ({ transaction_type }) => transaction_type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Trade Date",
                id: "trade_date_str",
                accessorFn: ({ trade_date_str }) => trade_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Contract",
                id: "contract",
                Cell: ({ row }) => (row?.original?.delivery_period_sym + " " + row?.original?.delivery_period_year + " " + row?.original?.delivery_period_month),
                size: 50
            },
            {
                header: "Start Date",
                id: "start_date_str",
                accessorFn: ({ start_date_str }) => start_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "End Date",
                id: "end_date_str",
                accessorFn: ({ end_date_str }) => end_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Capacity (MW)",
                id: "capacity",
                accessorFn: ({ capacity }) => capacity?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: "Volume (MWh)",
                id: "volume",
                accessorFn: ({ volume }) => volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            }
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key='edit' onClick={() => hanldeTransferHedge(row.original)}>
                        <CurrencyExchangeIcon sx={{ paddingRight: "10px" }} /> Trade
                    </MenuItem>,
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDeleteHedge(row.original)}>
                        <DeleteIcon sx={{ paddingRight: "10px" }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <ReactTable
            headerText={'Hedges'}
            columns={columns}
            data={hedges || []}
            actions={actionsMenu}
            isLoading={isGetHedgesLoading}
        />
    )
}

export default Hedge