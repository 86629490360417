import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    forecasts: [],
    forecastResult: {},
    isGetForecastLoading: false,
    isCreateForecastLoading: false,
    isDeleteForecastLoading: false,
    isGetForecastingResultWithBacktestingLoading: false
};

export const apiGetForecasts = createAsyncThunk(
    "forecasts/getForecast",
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_forecasting_list?type=auto');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateForecast = createAsyncThunk(
    "forecasts/createForecast",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_forecasting_auto', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteForecast = createAsyncThunk(
    "forecasts/apiDeleteForecast",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_forecast?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.statusText };
        } catch (error) {
            const err = error.response.data;
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const apiGetForecastingResultWithBacktesting = createAsyncThunk(
    "forecasts/apiGetForecastingResultWithBacktesting",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_forecasting_result_with_backtesting?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.statusText };
        } catch (error) {
            const err = error.response.data;
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const forecastingSlice = createSlice({
    name: "forecasts",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getForcasts
        builder
            .addCase(apiGetForecasts.pending, (state, action) => {
                state.isGetForecastLoading = true;
            })
            .addCase(apiGetForecasts.fulfilled, (state, action) => {
                state.isGetForecastLoading = false;
                state.forecasts = action.payload.data;
            })
            .addCase(apiGetForecasts.rejected, (state, action) => {
                state.isGetForecastLoading = false;
            });
        // createForcast
        builder
            .addCase(apiCreateForecast.pending, (state, action) => {
                state.isCreateForecastLoading = true;
            })
            .addCase(apiCreateForecast.fulfilled, (state, action) => {
                state.isCreateForecastLoading = false;
            })
            .addCase(apiCreateForecast.rejected, (state, action) => {
                state.isCreateForecastLoading = false;
            });
        // deleteForcast
        builder
            .addCase(apiDeleteForecast.pending, (state, action) => {
                state.isDeleteForecastLoading = true;
            })
            .addCase(apiDeleteForecast.fulfilled, (state, action) => {
                state.isDeleteForecastLoading = false;
            })
            .addCase(apiDeleteForecast.rejected, (state, action) => {
                state.isDeleteForecastLoading = false;
            });

        // getForecastingResultWithBacktesting
        builder
            .addCase(apiGetForecastingResultWithBacktesting.pending, (state, action) => {
                state.isGetForecastingResultWithBacktestingLoading = true;
            })
            .addCase(apiGetForecastingResultWithBacktesting.fulfilled, (state, action) => {
                state.isGetForecastingResultWithBacktestingLoading = false;
                state.forecastResult = action.payload.data;
            })
            .addCase(apiGetForecastingResultWithBacktesting.rejected, (state, action) => {
                state.isGetForecastingResultWithBacktestingLoading = false;
            });
    }
});

export default forecastingSlice.reducer;