import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

export default function ValuationChart({
    months,
    yearsList,
    selectedYear,
    selectedMonth,
    onYearChange,
    onMonthChange,
    isGetValuationLoading,
    valuation
}) {
    const transformedData = transformData(valuation);

    const daysInMonth = getDaysInMonth(selectedYear, selectedMonth);
    const dayTicks = [];

    return (
        <div className="h-[600px] w-full bg-white rounded p-8">
            <Box marginBottom={`14px`} display={'flex'}>
                <FormControl
                    size="small"
                    sx={{
                        minWidth: 'min-content',
                        marginRight: '28px'
                    }}
                >
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            fontSize: '12px',
                            marginTop: '5px'
                        }}
                    >
                        Year
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Year"
                        onChange={onYearChange}
                        value={selectedYear}
                        defaultValue={selectedYear}
                        sx={{
                            fontSize: '12px',
                            minWidth: '96px',
                            maxWidth: 'min-content'
                        }}
                    >
                        {yearsList.map((item, index) => (
                            <MenuItem
                                key={index}
                                value={item}
                                sx={{
                                    fontSize: '12px'
                                }}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    size="small"
                    sx={{
                        minWidth: 'min-content',
                        marginRight: '28px'
                    }}
                >
                    <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                            fontSize: '12px',
                            marginTop: '5px'
                        }}
                    >
                        Month
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Month"
                        onChange={onMonthChange}
                        value={selectedMonth}
                        defaultValue={selectedMonth}
                        sx={{
                            fontSize: '12px',
                            minWidth: '96px',
                            maxWidth: 'min-content'
                        }}
                    >
                        {months.map((item, index) => (
                            <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                    fontSize: '12px'
                                }}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {isGetValuationLoading ? (
                <div className="h-[90%] flex items-center justify-center">
                    Loading...
                </div>
            ) : (
                <ResponsiveContainer width="100%" height="95%">
                    <ComposedChart
                        width={500}
                        height={400}
                        data={transformedData}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" type="category" fontSize={12} />
                        <YAxis
                            fontSize={12}
                            tickCount={10}
                            interval="preserveEnd"
                        />
                        <Tooltip />
                        <Legend />
                        <Bar
                            stackId={'a'}
                            type="monotone"
                            dataKey="Valuation"
                            fill="#8BC1F7"
                            order={1}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </div>
    );
}

function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
}

function transformData(datasets) {
    const transformedData = [];

    // Get all the unique x-values across all datasets
    const uniqueXValues = new Set();
    datasets.forEach((dataset) => {
        dataset.data.forEach(([x]) => {
            uniqueXValues.add(x);
        });
    });

    const sortedXValues = [...uniqueXValues].sort((a, b) => a - b);

    // Create an object for each unique x-value
    sortedXValues.forEach((xValue) => {
        const dataPoint = { name: xValue };

        // Populate the dataset values for this x-value
        datasets.forEach((dataset) => {
            const { name: datasetName, data } = dataset;
            const yValue = data.find(([x]) => x === xValue)?.[1] || 0; // Find the y-value for this x-value, or use 0 if not found
            dataPoint[datasetName] = yValue;
        });

        transformedData.push(dataPoint);
    });

    return transformedData;
}
