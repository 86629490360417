import { Box } from '@mui/material';
import React from 'react';
import ReactTradingviewWidget from 'react-tradingview-widget';

const Analysis = () => {
    return (
        <Box sx={{ overflow: 'auto' }}>
            <ReactTradingviewWidget
                symbol="TTF1!"
                locale="en"
                hide_side_toolbar={false}
                datafeed_url="http://energyportfolio.ch/api/tvdata"
            />
        </Box>
    );
};

export default Analysis;
