export const PATH = {
    LOGIN : '/login',

    DASHBOARD: '/dashboard',
    HEDGES: '/hedges',
    OUTLOOK: '/outlook',
    ANALYSIS: '/analysis',
    GENERAL_FORCASTING: '/general-forecasting',
    AUTO_REPORTING: '/auto-reporting',
    AUTO_REPORTING_RESULT: '/auto-reporting',
    SEARCH: '/search',
    FORWARD: '/forward',
    HEDGE: '/hedge',
    HEDGE_TRANSFER: '/hedge-transfer',
    OPTIONS: '/options',
    COUNTERPARTY: '/counterparty',
    PORTFOLIO: '/portfolio',
    BROKERS: '/brokers',
    EXCHANGES: '/exchanges',
    ENDPOINTS: '/endpoints',
    SETTINGS: '/settings',
    CLIENTS: '/clients',
    USERS : '/users',
}