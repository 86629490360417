import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    getOverallHedgeGraphData,
    getOverallHedgeTransactionData,
    getOverallMevGraphData,
    getOverallProfileGraphData,
    getValuationGraphData
} from '../../../utils/fetchFromAPI';

const initialState = {
    pendingHedges: [],
    overallStatistics: {},
    hedges: [],
    hedge: {
        data: {},
        dataMw: {}
    },
    valuation: {
        data: undefined,
        dataMw: undefined
    },
    isGetHedgesLoading: false,
    isGetValuationLoading: false,
    isGetHedgeLoading: false,
    isHedgeLoading: false,
    isCreateHedgeLoading: false,
    isDeleteHedgeLoading: false,
    isPendingHedgeLoading: false,
    isTradeTransferLoading: false,
    isHedgeTransferLoading: false,
    isGetOverStatisticsLoading: false
};

export const apiGetHedges = createAsyncThunk(
    'hedges/getHedges',
    async ({ portfolio_name, portfolio_uuid }, thunkAPI) => {
        try {
            const response = await axios.get(
                `/api/hedges_raw_data?portfolioname=${portfolio_name}&portfoliouuid=${portfolio_uuid}`
            );
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetPendingHedges = createAsyncThunk(
    'hedges/getPendingHedges',
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/hedges_pending');
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateHedge = createAsyncThunk(
    'hedges/createHedges',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_hedge', data);
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetAllHedgeGraphData = createAsyncThunk(
    'hedge/getOverallHedgeGraphData',
    async ({ uuid, name }, thunkAPI) => {
        try {
            const hedgeResponseData = await getOverallHedgeGraphData(
                uuid,
                name
            );
            const mevResponseData = await getOverallMevGraphData(uuid, name);
            const demandResponseData = await getOverallProfileGraphData(
                uuid,
                name,
                'demand1',
                true
            );
            const transactionsResponseData =
                await getOverallHedgeTransactionData(uuid, name);

            const hedgeData = hedgeResponseData.data;
            const mevData = mevResponseData.data;
            const demandData = demandResponseData.data;
            const transactionsData = transactionsResponseData.data;
            const resultData = {
                gy: hedgeData.gy,
                month: hedgeData.month,
                others: hedgeData.others,
                quarter: hedgeData.quarter,
                summer: hedgeData.summer,
                winter: hedgeData.winter,
                year: hedgeData.year,
                demandOne: demandData.data,
                transactions: transactionsData.total_mwh,
                mev: mevData.data
            };

            const resultDataMw = {
                gy: hedgeData.gy_mw,
                month: hedgeData.month_mw,
                others: hedgeData.others_mw,
                quarter: hedgeData.quarter_mw,
                summer: hedgeData.summer_mw,
                winter: hedgeData.winter_mw,
                year: hedgeData.year_mw,
                demandOne: demandData.data_mw,
                transactions: transactionsData.total_mw,
                mev: mevData.data_mw
            };

            return {
                data: {
                    data: resultData,
                    dataMw: resultDataMw
                },
                status: 200,
                statusText: 'Everything went great!'
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetValuationGraphData = createAsyncThunk(
    'hedge/getValuationGraphData',
    async ({ uuid, name, year, month }, thunkAPI) => {
        try {
            const valuationResponseData = await getValuationGraphData(
                uuid,
                name,
                year,
                month
            );
            const valuationData = valuationResponseData.data;

            return {
                data: {
                    data: valuationData.graph_data.valuation,
                    dataMw: valuationData.graph_data_mw.valuation
                },
                status: 200,
                statusText: 'Everything went great!'
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteHedge = createAsyncThunk(
    'hedges/deleteHedge',
    async (hedge, thunkAPI) => {
        try {
            const response = await axios.post('/api/delete_hedge', hedge);
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiTradeTransfer = createAsyncThunk(
    'hedges/tradeTransfer',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/transfer_hedge_temp', data);
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiHedgeTransfer = createAsyncThunk(
    'hedges/hedgeTransfer',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/transfer_hedge', data);
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetOverStatistics = createAsyncThunk(
    'hedges/getOverStatistics',
    async ({ portfolio_name, portfolio_uuid }, thunkAPI) => {
        try {
            const response = await axios.get(
                `/api/get_hedges_valuation_table_data?portfolioname=${portfolio_name}&portfoliouuid=${portfolio_uuid}`
            );
            return {
                data: response.data,
                status: response.status,
                statusText: response.message
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const hedgeSlice = createSlice({
    name: 'hedges',
    initialState,
    reducers: {
        deleteHedge: (state, action) => {
            state.hedges = state.hedges.filter(
                (hedge) => hedge.uuid !== action.payload
            );
        }
    },
    extraReducers: (builder) => {
        // getHedges
        builder
            .addCase(apiGetHedges.pending, (state, action) => {
                state.isGetHedgesLoading = true;
            })
            .addCase(apiGetHedges.fulfilled, (state, action) => {
                state.isGetHedgesLoading = false;
                state.hedges = action.payload.data;
            })
            .addCase(apiGetHedges.rejected, (state, action) => {
                state.isGetHedgesLoading = false;
            });

        // getPendingHedges
        builder
            .addCase(apiGetPendingHedges.pending, (state, action) => {
                state.isPendingHedgeLoading = true;
            })
            .addCase(apiGetPendingHedges.fulfilled, (state, action) => {
                state.isPendingHedgeLoading = false;
                state.pendingHedges = action.payload.data;
            })
            .addCase(apiGetPendingHedges.rejected, (state, action) => {
                state.isPendingHedgeLoading = false;
            });

        // createHedges
        builder
            .addCase(apiCreateHedge.pending, (state, action) => {
                state.isCreateHedgeLoading = true;
            })
            .addCase(apiCreateHedge.fulfilled, (state, action) => {
                state.isCreateHedgeLoading = false;
            })
            .addCase(apiCreateHedge.rejected, (state, action) => {
                state.isCreateHedgeLoading = false;
            });

        // Get hedge graph data
        builder
            .addCase(apiGetAllHedgeGraphData.pending, (state, action) => {
                state.isGetHedgeLoading = true;
            })
            .addCase(apiGetAllHedgeGraphData.fulfilled, (state, action) => {
                state.isGetHedgeLoading = false;
                state.hedge = action.payload.data;
            })
            .addCase(apiGetAllHedgeGraphData.rejected, (state, action) => {
                state.isGetHedgeLoading = false;
            });

        // Get valuation data
        builder
            .addCase(apiGetValuationGraphData.pending, (state, action) => {
                state.isGetValuationLoading = true;
            })
            .addCase(apiGetValuationGraphData.fulfilled, (state, action) => {
                state.isGetValuationLoading = false;
                state.valuation = action.payload.data;
            })
            .addCase(apiGetValuationGraphData.rejected, (state, action) => {
                state.isGetValuationLoading = false;
            });

        // Transfer trade
        builder
            .addCase(apiTradeTransfer.pending, (state, action) => {
                state.isTradeTransferLoading = true;
            })
            .addCase(apiTradeTransfer.fulfilled, (state, action) => {
                state.isTradeTransferLoading = false;
            })
            .addCase(apiTradeTransfer.rejected, (state, action) => {
                state.isTradeTransferLoading = false;
            });

        // Transfer hedge
        builder
            .addCase(apiHedgeTransfer.pending, (state, action) => {
                state.isHedgeTransferLoading = true;
            })
            .addCase(apiHedgeTransfer.fulfilled, (state, action) => {
                state.isHedgeTransferLoading = false;
            })
            .addCase(apiHedgeTransfer.rejected, (state, action) => {
                state.isHedgeTransferLoading = false;
            });

        // deleteHedge
        builder
            .addCase(apiDeleteHedge.pending, (state, action) => {
                state.isDeleteHedgeLoading = true;
            })
            .addCase(apiDeleteHedge.fulfilled, (state, action) => {
                state.isDeleteHedgeLoading = false;
            })
            .addCase(apiDeleteHedge.rejected, (state, action) => {
                state.isDeleteHedgeLoading = false;
            });

        // getOverStatistics
        builder
            .addCase(apiGetOverStatistics.pending, (state, action) => {
                state.isGetOverStatisticsLoading = true;
            })
            .addCase(apiGetOverStatistics.fulfilled, (state, action) => {
                state.isGetOverStatisticsLoading = false;
                state.overallStatistics = action.payload.data;
            })
            .addCase(apiGetOverStatistics.rejected, (state, action) => {
                state.isGetOverStatisticsLoading = false;
            });
    }
});

export const { deleteHedge } = hedgeSlice.actions;

export default hedgeSlice.reducer;
