import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isGetSearchLoading: false,
    searchResults: [],
    searchError: null,
}

export const apiSearch = createAsyncThunk(
    "search/search",
    async ({ transactionid, startdate, enddate, paymentdate, instrumenttype, entrystatus, portfolio }, thunkAPI) => {
        try {
            let query = "/api/search?"
            if (transactionid) query += `transactionid=${transactionid}&`
            if (startdate) query += `start_date=${startdate}&`
            if (enddate) query += `end_date=${enddate}&`
            if (paymentdate) query += `payment_date=${paymentdate}&`
            if (instrumenttype) query += `instrument_type=${instrumenttype}&`
            if (entrystatus) query += `entrystatus=${entrystatus}&`
            if (portfolio) query += `portfolio=${portfolio}`

            const response = await axios.get(query);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        deleteForward: (state, action) => {
            state.searchResults = state.searchResults.filter((forward) => forward.uuid !== action.payload.uuid);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(apiSearch.pending, (state, action) => {
                state.isGetSearchLoading = true;
            })
            .addCase(apiSearch.fulfilled, (state, action) => {
                state.isGetSearchLoading = false;
                state.searchResults = action.payload.data;
            })
            .addCase(apiSearch.rejected, (state, action) => {
                state.isGetSearchLoading = false;
                state.searchError = action.payload;
                state.searchResults = [];
            })
    }
});

export const { deleteForward } = searchSlice.actions;

export default searchSlice.reducer;