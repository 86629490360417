import React, { useEffect, useMemo } from 'react'
import { ReactTable } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { apiGetOverStatistics } from '../../store';

const OverallStatistics = ({ selectedPortfolio }) => {
    const dispatch = useDispatch()
    const { overallStatistics, isGetOverStatisticsLoading } = useSelector(state => state.hedges)

    useEffect(() => {
        if (selectedPortfolio.name !== '' && selectedPortfolio.uuid !== '') {
            dispatch(apiGetOverStatistics({ portfolio_name: selectedPortfolio.name, portfolio_uuid: selectedPortfolio.uuid }))
        }
    }, [selectedPortfolio])

    const columns = useMemo(
        () => [
            {
                header: "Month",
                id: "Month",
                accessorFn: ({ Month }) => Month || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Year",
                id: "Year",
                accessorFn: ({ Year }) => Year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Hedge Vol (MWh)",
                id: "TotalVol",
                accessorFn: ({ TotalVol }) => TotalVol.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                Footer: () => <> Total: <span className='text-green-700'>{overallStatistics?.Totalhedgevol ? overallStatistics?.Totalhedgevol : '0.00'}</span> </>,
            },
            {
                header: "Hedge Amount (EUR)",
                id: "TotalCost",
                accessorFn: ({ TotalCost }) => TotalCost.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                Footer: () => <> Total: <span className='text-green-700'>{overallStatistics?.Totalhedgecost ? overallStatistics?.Totalhedgecost : '0.00'}</span> </>,
            },
            {
                header: "Average Price (EUR/MWh)",
                id: "contract",
                Cell: ({ row }) => isNaN(row?.original?.TotalCost / row?.original?.TotalVol) ? '0.00' : (row?.original?.TotalCost / row?.original?.TotalVol).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            },
            {
                header: "VaR (EUR)",
                id: "Var",
                accessorFn: ({ Var }) => Var.toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                Footer: () => <> Total: <span className='text-green-700'>{overallStatistics?.Totalvar ? overallStatistics?.Totalvar : '0.00'}</span> </>,
            }
        ], []
    );

    return (
        <ReactTable
            headerText={'Overall Statistics'}
            columns={columns}
            data={overallStatistics.Ret || []}
            isLoading={isGetOverStatisticsLoading}
        />
    )
}

export default OverallStatistics