import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    brokers: [],
    isGetBrokerLoading: false,
    isCreateBrokerLoading: false,
    isDeleteBrokerLoading: false
}

export const apiGetBroker = createAsyncThunk(
    "client/getBroker",
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_broker');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateBroker = createAsyncThunk(
    "broker/createBroker",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_broker', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteBroker = createAsyncThunk(
    "broker/apiDeleteBroker",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_broker?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.statusText };
        } catch (error) {
            const err = error.response.data;
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const brokerSlice = createSlice({
    name: 'broker',
    initialState,
    reducers: {
        deleteBroker: (state, action) => {
            state.brokers = state.brokers.filter((broker) => broker.uuid !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(apiGetBroker.pending, (state, action) => {
                state.isGetBrokerLoading = true;
            })
            .addCase(apiGetBroker.fulfilled, (state, action) => {
                state.isGetBrokerLoading = false;
                state.brokers = action.payload.data;
            })
            .addCase(apiGetBroker.rejected, (state, action) => {
                state.isGetBrokerLoading = false;
            });

        // create broker
        builder
            .addCase(apiCreateBroker.pending, (state, action) => {
                state.isCreateBrokerLoading = true;
            })
            .addCase(apiCreateBroker.fulfilled, (state, action) => {
                state.isCreateBrokerLoading = false;
            })
            .addCase(apiCreateBroker.rejected, (state, action) => {
                state.isCreateBrokerLoading = false;
            });

        // delete broker
        builder
            .addCase(apiDeleteBroker.pending, (state) => {
                state.isDeleteBrokerLoading = true;
            })
            .addCase(apiDeleteBroker.fulfilled, (state, action) => {
                state.isDeleteBrokerLoading = false;
            })
            .addCase(apiDeleteBroker.rejected, (state) => {
                state.isDeleteBrokerLoading = false;
            })
    }
})

export const { deleteBroker } = brokerSlice.actions;

export default brokerSlice.reducer;