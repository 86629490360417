import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    getMonthlyMevGraphData,
    getMonthlyProfileGraphData,
    getMonthlyTransactionGraphData
} from '../../../utils/fetchFromAPI';

const initialState = {
    monthlyConsumption: { data: [], dataMw: [] },
    isGetConsumptionMonthlyLoading: false
};

export const apiGetMonthlyGraphData = createAsyncThunk(
    'consumption/getMonthlyProfileGraphData',
    async ({ uuid, portfolioName, year, month, monthNumber }, thunkAPI) => {
        try {
            const transactionsGraphData = await getMonthlyTransactionGraphData(
                uuid,
                portfolioName,
                year,
                month
            );
            const profileGraphData = await getMonthlyProfileGraphData(
                uuid,
                portfolioName,
                year,
                month
            );

            const mevGraphData = await getMonthlyMevGraphData(
                uuid,
                portfolioName,
                year,
                monthNumber
            );

            const extractedTransactionsData =
                transactionsGraphData.data.graph_data;
            const extractedTransactionsMwData =
                transactionsGraphData.data.graph_data_mw;
            const extractedProfileData = profileGraphData.data.data;
            const extractedProfileMwData = profileGraphData.data.data_mw;
            const extractedMevData = mevGraphData.data.total;
            const extractedMevMwData = mevGraphData.data.totalMW;

            const totalData = {
                gy: extractedTransactionsData.gy,
                month: extractedTransactionsData.month,
                others: extractedTransactionsData.others,
                quarter: extractedTransactionsData.quarter,
                summer: extractedTransactionsData.summer,
                winter: extractedTransactionsData.winter,
                year: extractedTransactionsData.year,
                profileData: extractedProfileData,
                mev: extractedMevData
            };

            const totalDataMw = {
                gy: extractedTransactionsMwData.gy,
                month: extractedTransactionsMwData.month,
                others: extractedTransactionsMwData.others,
                quarter: extractedTransactionsMwData.quarter,
                summer: extractedTransactionsMwData.summer,
                winter: extractedTransactionsMwData.winter,
                year: extractedTransactionsMwData.year,
                profileData: extractedProfileMwData,
                mev: extractedMevMwData
            };

            const resultData = {
                data: totalData,
                dataMw: totalDataMw
            };

            return {
                data: resultData,
                status: 200,
                statusText: 'Everything went great'
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const monthlyConsumptionSlice = createSlice({
    name: 'monthlyConsumptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(apiGetMonthlyGraphData.pending, (state, action) => {
                state.isGetConsumptionMonthlyLoading = true;
            })
            .addCase(apiGetMonthlyGraphData.fulfilled, (state, action) => {
                state.isGetConsumptionMonthlyLoading = false;
                state.monthlyConsumption = action.payload.data;
            })
            .addCase(apiGetMonthlyGraphData.rejected, (state, action) => {
                state.isGetConsumptionMonthlyLoading = false;
            });
    }
});

export default monthlyConsumptionSlice.reducer;
