import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { PATH } from '../resources'

const NoAuth = () => {
    const { auth } = useAuth()
    const to = PATH.DASHBOARD

    return (
        auth.session
            ? <Navigate to={to} replace />
            : <Outlet />
    )
}

export default NoAuth