import React, { forwardRef, useState } from 'react'
import { Box, Button, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { apiGetPendingHedges, apiHedgeTransfer } from '../../store'
import { showToast } from '../../utils';

const TransferHedge = forwardRef(({ handleClose, hedge }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        price: '',
        price_str: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const updatedHedge = {
            ...hedge,
            price: parseFloat(state.price),
            price_str: state.price.toString()
        }
        const resp = await dispatch(apiHedgeTransfer(updatedHedge))
        if (resp.meta.requestStatus === 'fulfilled') {
            dispatch(apiGetPendingHedges())
            handleClose()
            showToast('success', 'Hedge transferred successfully')
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp?.payload)
        } else {
            showToast('error', 'Something went wrong, please try again later')
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Transfer Hedge</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        id="fixedPriceLabel"
                        sx={{ paddingRight: '60px' }}
                    >
                        Fixed Price [EUR/MWh]
                    </InputLabel>
                    <OutlinedInput
                        type='number'
                        id="fixed_price"
                        label="Fixed Price [EUR/MWh]"
                        name="price"
                        inputProps={{ step: 0.01 }}
                        value={state.price}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    )
})

export default TransferHedge