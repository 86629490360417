import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    clients: [],
    isClientLoading: false,
    isUpdateClientLoading: false,
};

export const apiGetClients = createAsyncThunk(
    "client/getClients",
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_clients');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdateClient = createAsyncThunk(
    "client/updateClient",
    async ({ client_margin, client_uuid }, thunkAPI) => {
        try {
            const response = await axios.post(`/api/update_client_margin?margin=${client_margin}&account_uuid=${client_uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getClients
        builder
            .addCase(apiGetClients.pending, (state, action) => {
                state.isClientLoading = true;
            })
            .addCase(apiGetClients.fulfilled, (state, action) => {
                state.isClientLoading = false;
                state.clients = action.payload.data;
            })
            .addCase(apiGetClients.rejected, (state, action) => {
                state.isClientLoading = false;
            });

        // updateClient
        builder
            .addCase(apiUpdateClient.pending, (state, action) => {
                state.isUpdateClientLoading = true;
            })
            .addCase(apiUpdateClient.fulfilled, (state, action) => {
                state.isUpdateClientLoading = false;
            })
            .addCase(apiUpdateClient.rejected, (state, action) => {
                state.isUpdateClientLoading = false;
            });
    }
});

export default clientSlice.reducer;