import React from 'react'
import { CommonContainer } from '../../components'
import { Box, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector } from 'react-redux';

const CircularIconComponent = ({ bgColor, children }) => {
    return (
        <Box
            sx={{
                border: 'none',
                borderRadius: '25px',
                background: bgColor,
                color: 'white',
                height: '24px',
                width: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {children}
        </Box>
    )
}

const Profile = () => {
    const { user } = useSelector((state) => state.user);
    return (
        <CommonContainer topic='Profile'>
            <Box className='flex flex-col gap-7 mt-3'>
                <Typography sx={{ fontSize: '1.5rem', mb: '22px' }}>{user.fullname}</Typography>
                <Box className='flex flex-col gap-2'>
                    <Box className='flex items-center gap-2'>
                        <CircularIconComponent bgColor={'#18A899'}>
                            <EmailIcon sx={{ fontSize: '16px' }} />
                        </CircularIconComponent>
                        <Typography> Email</Typography>
                    </Box>
                    <Box className='flex gap-3'>
                        <Typography sx={{ fontSize: '14px' }}> {user.email}</Typography>
                    </Box>
                </Box>
                <Box className='flex flex-col gap-2'>
                    <Box className='flex items-center gap-2'>
                        <CircularIconComponent bgColor={'#3758FF'}>
                            <PersonIcon sx={{ fontSize: '16px' }} />
                        </CircularIconComponent>
                        <Typography> Username</Typography>
                    </Box>
                    <Box className='flex gap-3'>
                        <Typography sx={{ fontSize: '14px' }}>{user.username}</Typography>
                    </Box>
                </Box>
            </Box>
        </CommonContainer>
    )
}

export default Profile