import { Box, Button, FormControl, TextField, Typography } from '@mui/material'
import React, { forwardRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import ReactFileReader from 'react-file-reader';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { apiCreatePost, apiGetPosts } from '../../store';
import { useDispatch } from 'react-redux';
import { showToast } from '../../utils';

const UploadPost = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        title: '',
        postFile: '',
        postUrl: ''
    })

    const handleFiles = (files) => {
        var reader = new FileReader();
        reader.onload = function (e) {
            setState({
                ...state,
                postFile: files[0],
                postUrl: e.target.result
            })
        }
        reader.readAsDataURL(files[0]);
    }

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (state.postFile === '') {
            showToast('error', 'Please upload an image')
            return
        } else {
            const formData = new FormData()
            formData.append('title', state.title)
            formData.append('postFile', state.postFile)
            const response = await dispatch(apiCreatePost(formData))
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Post created successfully')
                dispatch(apiGetPosts())
                handleClose()
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response.payload)
            }
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Create new post</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <FormControl fullWidth={true}>
                    <TextField
                        className='modal-input'
                        onChange={handleChange}
                        id="title"
                        label="Title"
                        variant="outlined"
                        name="title"
                        required
                    />
                    <Box className='border-dashed border-2 border-[#C0C0C0] mt-2'>
                        <ReactFileReader handleFiles={handleFiles} fileTypes={'.png,.jpg,.jpeg'} >
                            {state?.postFile ?
                                <Box className='relative'>
                                    <img src={state.postUrl} alt={state.title} className='object-cover h-80 w-full' />
                                    <Box className='absolute top-0 right-0 mt-1 mr-1 bg-white p-1 rounded-full cursor-pointer'>
                                        <CloseIcon onClick={() => setState({ ...state, image: '' })} />
                                    </Box>
                                </Box>
                                :
                                <>
                                    <Box className='flex flex-col items-center justify-center h-80'>
                                        <FileUploadIcon fontSize='large' />
                                        <Typography caption='caption'>Upload Image</Typography>
                                    </Box>
                                </>
                            }
                        </ReactFileReader>
                    </Box>
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    )
})

export default UploadPost