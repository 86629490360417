import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getOverallMevGraphData,
    getOverallProfileGraphData,
    getOverallTransactionGraphData
} from '../../../utils/fetchFromAPI';

const initialState = {
    consumption: { data: {}, dataMw: {} },
    isGetConsumptionLoading: false
};

export const apiGetAllGraphData = createAsyncThunk(
    'consumption/getOverallTransactionGraphData',
    async ({ uuid, name }, thunkAPI) => {
        try {
            const transactionsGraphData = await getOverallTransactionGraphData(
                uuid,
                name
            );

            const demandTypes = [
                'demand1',
                'demand2',
                'demand3',
                'demand4',
                'demand5'
            ];

            const [
                demandOneData,
                demandTwoData,
                demandThreeData,
                demandFourData,
                demandFiveData
            ] = await Promise.all(
                demandTypes.map((demandType) =>
                    getOverallProfileGraphData(uuid, name, demandType)
                )
            );
            const mevData = await getOverallMevGraphData(uuid, name);

            const transactionData = transactionsGraphData.data;
            const totalData = {
                gy: transactionData.gy,
                month: transactionData.month,
                others: transactionData.others,
                quarter: transactionData.quarter,
                summer: transactionData.summer,
                winter: transactionData.winter,
                year: transactionData.year,
                demandOne: demandOneData.data.data,
                demandTwo: demandTwoData.data.data,
                demandThree: demandThreeData.data.data,
                demandFour: demandFourData.data.data,
                demandFive: demandFiveData.data.data,
                mev: mevData.data.data
            };

            const totalDataMw = {
                gy: transactionData.gy_mw,
                month: transactionData.month_mw,
                others: transactionData.others_mw,
                quarter: transactionData.quarter_mw,
                summer: transactionData.summer_mw,
                winter: transactionData.winter_mw,
                year: transactionData.year_mw,
                demandOne: demandOneData.data.data_mw,
                demandTwo: demandTwoData.data.data_mw,
                demandThree: demandThreeData.data.data_mw,
                demandFour: demandFourData.data.data_mw,
                demandFive: demandFiveData.data.data_mw,
                mev: mevData.data.data_mw
            };

            return {
                data: { data: totalData, dataMw: totalDataMw },
                status: 200,
                statusText: 'Everything went great!'
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const consumptionSlice = createSlice({
    name: 'consumptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get consumptions
        builder
            .addCase(apiGetAllGraphData.pending, (state, action) => {
                state.isGetConsumptionLoading = true;
            })
            .addCase(apiGetAllGraphData.fulfilled, (state, action) => {
                state.isGetConsumptionLoading = false;
                state.consumption = action.payload.data;
            })
            .addCase(apiGetAllGraphData.rejected, (state, action) => {
                state.isGetConsumptionLoading = false;
            });
    }
});

export default consumptionSlice.reducer;
