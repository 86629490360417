import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    portfolio: [],
    isGetPortfolioLoading: false,
    isCreatePortfolioLoading: false,
    isUploadProfileLoading: false,
    isChangeUsersLoading: false,
    isEditPortfolioLoading: false,
    isDeletePortfolioLoading: false,
    isUpdatePortfolioLoading: false,
    isUpdatePortfolioUsersLoading: false,
    isUploadMEVLoading: false,
};

export const apiGetPortfolio = createAsyncThunk(
    "portfolio/getPortfolio",
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_portfolio', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreatePortfolio = createAsyncThunk(
    "portfolio/createPortfolio",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_portfolio', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUploadProfile = createAsyncThunk(
    "portfolio/uploadProfile",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/upload_profile', formData);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdatePortfolio = createAsyncThunk(
    "portfolio/updatePortfolio",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/update_portfolio', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUploadMEV = createAsyncThunk(
    "portfolio/uploadMEV",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/upload_mev_data', formData);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteMEVData = createAsyncThunk(
    "portfolio/deleteMEVData",
    async ({ mev_name, portfolio_uuuid, portfolio_name }, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_mev_data?name=${mev_name}&portfoliouuuid=${portfolio_uuuid}&portfolioname=${portfolio_name}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeletePortfolio = createAsyncThunk(
    "portfolio/deletePortfolio",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_portfolio?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdatePortfolioUsers = createAsyncThunk(
    "portfolio/updatePortfolioUsers",
    async (usersuuid, thunkAPI) => {
        try {
            const response = await axios.post('/api/update_portfolio_users', usersuuid);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiReuploadCSVData = createAsyncThunk(
    "portfolio/reuploadCSVData",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/reupload_csv_data', formData);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const portfolioSlice = createSlice({
    name: "portfolio",
    initialState,
    reducers: {
        deletePortfolio: (state, action) => {
            state.portfolio = state.portfolio.filter((portfolio) => portfolio.uuid !== action.payload);
        },
    },
    extraReducers: (builder) => {
        // get portfolio
        builder
            .addCase(apiGetPortfolio.pending, (state, action) => {
                state.isGetPortfolioLoading = true;
            })
            .addCase(apiGetPortfolio.fulfilled, (state, action) => {
                state.isGetPortfolioLoading = false;
                state.portfolio = action.payload.data;
            })
            .addCase(apiGetPortfolio.rejected, (state, action) => {
                state.isGetPortfolioLoading = false;
            });

        // create portfolio
        builder
            .addCase(apiCreatePortfolio.pending, (state, action) => {
                state.isCreatePortfolioLoading = true;
            })
            .addCase(apiCreatePortfolio.fulfilled, (state, action) => {
                state.isCreatePortfolioLoading = false;
            })
            .addCase(apiCreatePortfolio.rejected, (state, action) => {
                state.isCreatePortfolioLoading = false;
            });

        // upload profile
        builder
            .addCase(apiUploadProfile.pending, (state, action) => {
                state.isUploadProfileLoading = true;
            })
            .addCase(apiUploadProfile.fulfilled, (state, action) => {
                state.isUploadProfileLoading = false;
            })
            .addCase(apiUploadProfile.rejected, (state, action) => {
                state.isUploadProfileLoading = false;
            });

        // delete portfolio
        builder
            .addCase(apiDeletePortfolio.pending, (state, action) => {
                state.isDeletePortfolioLoading = true;
            })
            .addCase(apiDeletePortfolio.fulfilled, (state, action) => {
                state.isDeletePortfolioLoading = false;
            })
            .addCase(apiDeletePortfolio.rejected, (state, action) => {
                state.isDeletePortfolioLoading = false;
            });

        // upload MEV
        builder
            .addCase(apiUploadMEV.pending, (state, action) => {
                state.isUploadMEVLoading = true;
            })
            .addCase(apiUploadMEV.fulfilled, (state, action) => {
                state.isUploadMEVLoading = false;
            })
            .addCase(apiUploadMEV.rejected, (state, action) => {
                state.isUploadMEVLoading = false;
            });

        // update portfolio
        builder
            .addCase(apiUpdatePortfolio.pending, (state, action) => {
                state.isUpdatePortfolioLoading = true;
            })
            .addCase(apiUpdatePortfolio.fulfilled, (state, action) => {
                state.isUpdatePortfolioLoading = false;
            })
            .addCase(apiUpdatePortfolio.rejected, (state, action) => {
                state.isUpdatePortfolioLoading = false;
            });

        // update portfolio users
        builder
            .addCase(apiUpdatePortfolioUsers.pending, (state, action) => {
                state.isUpdatePortfolioUsersLoading = true;
            })
            .addCase(apiUpdatePortfolioUsers.fulfilled, (state, action) => {
                state.isUpdatePortfolioUsersLoading = false;
            })
            .addCase(apiUpdatePortfolioUsers.rejected, (state, action) => {
                state.isUpdatePortfolioUsersLoading = false;
            });
    }
});

export const { deletePortfolio } = portfolioSlice.actions;

export default portfolioSlice.reducer;