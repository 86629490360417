import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isCreateOptionLoading: false,
}

export const apiCreateOption = createAsyncThunk(
    "option/createOption",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_options', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const optionSlice = createSlice({
    name: "option",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(apiCreateOption.pending, (state, action) => {
                state.isCreateOptionLoading = true;
            })
            .addCase(apiCreateOption.fulfilled, (state, action) => {
                state.isCreateOptionLoading = false;
            })
            .addCase(apiCreateOption.rejected, (state, action) => {
                state.isCreateOptionLoading = false;
            })
    }
});

export default optionSlice.reducer;