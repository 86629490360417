import axios from 'axios';

export const getOverallTransactionGraphData = async (uuid, name) => {
    try {
        const response = await axios.get(
            `/api/get_transactions_graph_data_overall?portfolioname=${name}&portfoliouuid=${uuid}`
        );
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getOverallProfileGraphData = async (
    uuid,
    name,
    dataType,
    isForHedge
) => {
    let url = `/api/get_csvprofile_graph_data_overall?portfolioname=${name}&portfoliouuid=${uuid}&data_type=${dataType}`;

    if (isForHedge) {
        url = url + '&type=hedge';
    }

    try {
        const response = await axios.get(url);
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getOverallMevGraphData = async (uuid, name) => {
    try {
        const response = await axios.get(
            `/api/get_mev_data?portfolioname=${name}&portfoliouuid=${uuid}`
        );
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getMonthlyProfileGraphData = async (uuid, name, year, month) => {
    try {
        const response = await axios.get(
            `/api/get_csvprofile_graph_data?portfolioname=${name}&portfoliouuid=${uuid}&year=${year}&month=${month}`
        );
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getMonthlyTransactionGraphData = async (
    uuid,
    name,
    year,
    month
) => {
    try {
        const response = await axios.get(
            `/api/get_transactions_graph_data?portfolioname=${name}&portfoliouuid=${uuid}&year=${year}&month=${month}`
        );

        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getOverallHedgeGraphData = async (uuid, name) => {
    let url = `/api/get_transactions_graph_data_overall?portfolioname=${name}&portfoliouuid=${uuid}&type=hedge`;
    try {
        const response = await axios.get(url);

        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getOverallHedgeTransactionData = async (uuid, name) => {
    const url = `/api/get_transactions_monthly_totals?portfolioname=${name}&portfoliouuid=${uuid}`;

    try {
        const response = await axios.get(url);
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getValuationGraphData = async (uuid, name, year, month) => {
    const url = `/api/get_hedges_valuation_graph_data?portfolioname=${name}&portfoliouuid=${uuid}&year=${year}&month=${month}&type=hedge`;

    try {
        const response = await axios.get(url);
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};

export const getMonthlyMevGraphData = async (uuid, name, year, month) => {
    const url = `/api/get_mev_data_monthly?portfolioname=${name}&portfoliouuid=${uuid}&year=${year}&month=${month}`;

    try {
        const response = await axios.get(url);
        return {
            data: response.data,
            status: response.status,
            statusText: response.message
        };
    } catch (error) {
        throw error;
    }
};
