import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    user: {},
    users: [],
    isLoginLoading: false,
    isGetProfileLoading: false,
    isChangePasswordLoading: false,
    isGetUsersLoading: false,
    isCreateUserLoading: false,
    isDeleteUserLoading: false,
    isLoggingOut: false,
};

export const apiLogin = createAsyncThunk(
    "user/login",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/login', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiLogout = createAsyncThunk(
    "user/logout",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/logout');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetProfile = createAsyncThunk(
    "user/getUser",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_user_me');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiChangePassword = createAsyncThunk(
    "user/changePassword",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/update_mypassword', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetUsers = createAsyncThunk(
    "users/getUsers",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_users');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const apiCreateUser = createAsyncThunk(
    "users/createUser",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_user', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const apiDeleteUser = createAsyncThunk(
    "users/deleteUser",
    async (user, thunkAPI) => {
        try {
            const response = await axios.post('/api/delete_user', user);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
)

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        deleteUser: (state, action) => {
            state.users = state.users.filter((user) => user.uuid !== action.payload);
        }
    },
    extraReducers: (builder) => {
        // login
        builder
            .addCase(apiLogin.pending, (state, action) => {
                state.isLoginLoading = true;
            })
            .addCase(apiLogin.fulfilled, (state, action) => {
                state.isLoginLoading = false;
            })
            .addCase(apiLogin.rejected, (state, action) => {
                state.isLoginLoading = false;
            })

        // get user
        builder
            .addCase(apiGetProfile.pending, (state, action) => {
                state.isGetProfileLoading = true;
            })
            .addCase(apiGetProfile.fulfilled, (state, action) => {
                state.isGetProfileLoading = false;
                state.user = action.payload.data;
            })
            .addCase(apiGetProfile.rejected, (state, action) => {
                state.isGetProfileLoading = false;
            })

        // change password
        builder
            .addCase(apiChangePassword.pending, (state, action) => {
                state.isChangePasswordLoading = true;
            })
            .addCase(apiChangePassword.fulfilled, (state, action) => {
                state.isChangePasswordLoading = false;
            })
            .addCase(apiChangePassword.rejected, (state, action) => {
                state.isChangePasswordLoading = false;
            })

        // get users
        builder
            .addCase(apiGetUsers.pending, (state, action) => {
                state.isGetUsersLoading = true;
            })
            .addCase(apiGetUsers.fulfilled, (state, action) => {
                state.isGetUsersLoading = false;
                state.users = action.payload.data;
            })
            .addCase(apiGetUsers.rejected, (state, action) => {
                state.isGetUsersLoading = false;
            })

        // create user
        builder
            .addCase(apiCreateUser.pending, (state, action) => {
                state.isCreateUserLoading = true;
            })
            .addCase(apiCreateUser.fulfilled, (state, action) => {
                state.isCreateUserLoading = false;
            })
            .addCase(apiCreateUser.rejected, (state, action) => {
                state.isCreateUserLoading = false;
            })

        // delete user
        builder
            .addCase(apiDeleteUser.pending, (state, action) => {
                state.isDeleteUserLoading = true;
            })
            .addCase(apiDeleteUser.fulfilled, (state, action) => {
                state.isDeleteUserLoading = false;
            })
            .addCase(apiDeleteUser.rejected, (state, action) => {
                state.isDeleteUserLoading = false;
            })

        // logout
        builder
            .addCase(apiLogout.pending, (state, action) => {
                state.isLoggingOut = true;
            })
            .addCase(apiLogout.fulfilled, (state, action) => {
                state.isLoggingOut = false;
            })
            .addCase(apiLogout.rejected, (state, action) => {
                state.isLoggingOut = false;
            })
    },
});

export const { deleteUser } = userSlice.actions;

export default userSlice.reducer;