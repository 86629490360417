import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import outlookReducer from './outlook/outlookSlice';
import clientReducer from './client/clientSlice';
import portfolioReducer from './portfolio/portfolioSlice';
import endpointReducer from './endpoints/endpointSlice';
import exchangeReducer from './exchange/exchangeSlice';
import brokerReducer from './broker/brokerSlice';
import counterPartyReducer from './counterparty/counterpartySlice';
import forecastingReducer from './forecasting/forecastingSlice';
import consumptionReducer from './consumption/consumptionSlice';
import monthlyConsumptionReducer from './consumption/consumptionMonthlySlice';
import hedgesReducer from './hedges/hedgeSlice';
import forwardReducer from './forward/forwardSlice';
import optionReducer from './option/optionSlice';
import searchReducer from './search/searchSlice';
import statisticsReducer from './statistics/statisticsSlice';
import transactionReducer from './transaction/transactionSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        outlook: outlookReducer,
        client: clientReducer,
        portfolio: portfolioReducer,
        endpoints: endpointReducer,
        exchanges: exchangeReducer,
        broker: brokerReducer,
        counterParty: counterPartyReducer,
        forecasts: forecastingReducer,
        hedges: hedgesReducer,
        forward: forwardReducer,
        option: optionReducer,
        search: searchReducer,
        consumption: consumptionReducer,
        monthlyConsumption: monthlyConsumptionReducer,
        statistics: statisticsReducer,
        transaction: transactionReducer
    }
});
