import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    transactions: [],
    loading: false,
}

export const apiGetTransaction = createAsyncThunk(
    "statistics/getTransaction",
    async ({ portfolioname, portfoliouuuid, fmonth, fyear, tmonth, tyear }, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_transactions_raw_data?portfolioname=${portfolioname}&portfoliouuid=${portfoliouuuid}&fmonth=${fmonth}&fyear=${fyear}&tmonth=${tmonth}&tyear=${tyear}`);
            return { data: response.data, status: response.status, statusText: response.message };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(apiGetTransaction.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(apiGetTransaction.fulfilled, (state, action) => {
                state.loading = false;
                state.transactions = action.payload.data;
            })
            .addCase(apiGetTransaction.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export default transactionSlice.reducer;