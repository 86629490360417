import React, { useState } from 'react'
import { Box, Button, Modal, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { useSelector } from 'react-redux'
import UploadPost from './UploadPost';
import { apiDeletePost, deletePost } from '../../store';
import { useDispatch } from 'react-redux';
import { showToast } from '../../utils';

const Outlook = () => {
    const dispatch = useDispatch()
    const { posts } = useSelector(state => state.outlook)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    const handleDeletePost = async (post) => {
        if (window.confirm("Are you sure you want to delete this post?")) {
            const response = await dispatch(apiDeletePost(post))
            if (response.meta.requestStatus === 'fulfilled') {
                showToast('info', 'Post deleted successfully')
                dispatch(deletePost(post.uuid))
            } else if (response.meta.requestStatus === 'rejected') {
                showToast('error', response.payload)
            } else {
                showToast('error', 'Something went wrong')
            }
        }
    }

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <UploadPost handleClose={handleCloseModal} />
            </Modal>
            <Box className='flex justify-end'>
                <Button variant="contained" color="primary" className='flex items-center gap-2' onClick={handleOpenModal}>
                    <PostAddOutlinedIcon fontSize='small' />
                    <Typography variant='body2'>New Post</Typography>
                </Button>
            </Box>
            <Box className='w-[700px] mx-auto'>
                {posts?.map(post => (
                    <Box key={post.uuid} className='border p-4 m-4 rounded-md shadow-md bg-white'>
                        <Box className='flex justify-between'>
                            <Box>
                                <Typography>{post.title}</Typography>
                                <Typography variant='caption'>{post.createdAt}</Typography>
                            </Box>
                            <DeleteIcon
                                color='error'
                                className='cursor-pointer'
                                onClick={() => handleDeletePost(post)}
                            />
                        </Box>
                        <img src={post.location} alt={post.title} height={300} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default Outlook