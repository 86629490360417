import React from "react";
import { Typography } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const ReactTable = (props) => {
    const {
        data,
        isLoading,
        handleRowClick,
        columns,
        actions,
        headerText,
        renderToolbarInternalActions,
        showCursorPointerOnRowHover,
    } = props;

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: !!actions,
        renderRowActionMenuItems: actions,
        positionActionsColumn: "last",
        state: {
            isLoading: isLoading,
        },
        initialState: {
            density: "compact",
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 20 },
        },
        renderToolbarInternalActions: renderToolbarInternalActions && renderToolbarInternalActions,
        renderTopToolbarCustomActions: () => (
            <Typography component="span" variant="h6">
                {headerText}
            </Typography>
        ),
        muiTableBodyCellProps: ({ cell }) => ({
            sx: {
                fontSize: "0.8rem",
                cursor: "pointer",
            },
            onClick: () => {
                handleRowClick && handleRowClick(cell?.row?.original);
            },
        }),
        // enableColumnResizing: true,
        // columnResizeMode: "flex",
        enableGrouping: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableFilters: false,
        enableColumnActions: false,
        enableSorting: false,
        muiTableBodyRowProps: ({ row }) => ({
            sx: {
                cursor:
                    (row?.original?.host_url && handleRowClick && "pointer") ||
                    (showCursorPointerOnRowHover && "pointer"),
            },
        }),
        muiBottomToolbarProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
        },
        muiTableProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
        },
        muiToolbarAlertBannerChipProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
        },
        muiTableBodyProps: {
            onMouseDown: (event) => {
                event.stopPropagation();
            },
            sx: {
                //stripe the rows, make odd rows a darker color
                "& > tr:nth-of-type(odd)": {
                    backgroundColor: "#f5f5f5",
                },
            },
        },
        muiSearchTextFieldProps: {
            variant: "standard",
            size: "small",
            sx: {
                width: "100%",
                maxWidth: "150px",
                top: "50%",
                transform: "translateY(-50%)",
            },
        },
        // layoutMode: "auto",
        muiTableContainerProps: {
            sx: {
                maxHeight: "calc(100vh - 150px)",
                overflow: "auto",
            },
        },
        muiTableHeadCellProps: {
            sx: {
                fontWeight: 'bold',
                fontSize: '12px',
            },
        },
    })

    return (
        <MaterialReactTable
            table={table}
            {...props}            
        />
    );
};

export default ReactTable;
