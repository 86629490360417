import React, { useEffect, useMemo } from 'react'
import { CommonContainer, ReactTable } from '../../components'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { apiGetForecastingResultWithBacktesting } from '../../store'
import { useDispatch, useSelector } from 'react-redux'

const AutoReportingResult = () => {
    const dispatch = useDispatch()
    const { uuid } = useParams()
    const { forecastResult } = useSelector((state) => state.forecasts)

    useEffect(() => {
        dispatch(apiGetForecastingResultWithBacktesting(uuid))
    }, [dispatch, uuid])

    let forecastingResult = forecastResult?.forecasting?.length > 0 && (forecastResult?.forecasting[0]?.forecast || [])
    let backtestingStatisticsResult = forecastResult?.backtesting?.backtest?.length > 0 && (forecastResult?.backtesting?.backtest[0] || [])

    let forcastingResultImage = forecastResult?.forecasting?.length > 0 && forecastResult?.forecasting[0]?.image_location
    let backtestingResultImage = forecastResult?.backtesting?.image_location

    const forecastResultColumn = useMemo(
        () => [
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Match Index",
                id: "match",
                accessorFn: ({ match }) => match || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "3 Days %",
                id: "three_day",
                accessorFn: ({ three_day }) => (Math.round(three_day * 100) / 100).toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "5 Days %",
                id: "five_day",
                accessorFn: ({ five_day }) => (Math.round(five_day * 100) / 100).toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "10 Days %",
                id: "ten_day",
                accessorFn: ({ ten_day }) => (Math.round(ten_day * 100) / 100).toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const backtestingStatisticsColumn = useMemo(
        () => [
            {
                header: "Date",
                id: "date",
                Cell: ({ row }) => `${row.original.day}-${row.original.month}-${row.original.year}`
            },
            {
                header: "Stock Price",
                id: "stock_price",
                accessorFn: ({ stock_price }) => stock_price.toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Forecast 3 Day",
                id: "three_day",
                accessorFn: ({ three_day }) => (Math.round(three_day * 100) / 100).toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Forecast 5 Day",
                id: "five_day",
                accessorFn: ({ five_day }) => (Math.round(five_day * 100) / 100).toFixed(2) || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    return (
        <Box display={'flex'} flexDirection={'column'} gap={3}>
            <CommonContainer topic={'Forecasting Result'} >
                <Box>
                    <img src={forcastingResultImage} alt="" />
                </Box>
            </CommonContainer>
            <ReactTable
                headerText={'Forecasting Result'}
                columns={forecastResultColumn}
                data={forecastingResult}
            />
            <CommonContainer topic={'Backtesting Result'} >
                <Box>
                    <img src={backtestingResultImage} alt="" />
                </Box>
            </CommonContainer>
            <ReactTable
                headerText={'Backtesting Statistics'}
                columns={backtestingStatisticsColumn}
                data={backtestingStatisticsResult}
            />
        </Box>
    )
}

export default AutoReportingResult