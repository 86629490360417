import React, { forwardRef, useState } from 'react'
import { Box, Button, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { showToast } from '../../utils';
import { apiGetClients, apiUpdateClient } from '../../store';

const EditClient = forwardRef(({ Client, handleClose }, ref) => {
    const dispatch = useDispatch()

    const [state, setState] = useState({
        client_margin: Client.margin,
        client_uuid: Client.uuid
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const resp = await dispatch(apiUpdateClient(state))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            handleClose()
            dispatch(apiGetClients())
            showToast('success', 'Client updated successfully')
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Create Portfolio</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        id="clientMarginLabel"
                    >
                        Name
                    </InputLabel>
                    <OutlinedInput
                        type='number'
                        id="client_margin"
                        label="Client Margin"
                        name="client_margin"
                        inputProps={{ step: 0.01 }}
                        value={state.client_margin}
                        onChange={handleChange}
                        required
                    />
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    )
})

export default EditClient