import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    counterParty: [],
    isGetCounterPartyLoading: false,
    isCreateCounterPartyLoading: false,
    isDeleteCounterPartyLoading: false,
};

export const apiGetCounterParty = createAsyncThunk(
    "counterparty/getCounterParty",
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_counterparty');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateCounterParty = createAsyncThunk(
    "counterparty/createCounterParty",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_counterparty', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteCounterParty = createAsyncThunk(
    "counterparty/apiDeleteCounterParty",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_counterparty?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.statusText };
        } catch (error) {
            const err = error.response.data;
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const counterPartySlice = createSlice({
    name: "counterParty",
    initialState,
    reducers: {
        deleteCounterParty: (state, action) => {
            state.counterParty = state.counterParty.filter((counterParty) => counterParty.uuid !== action.payload);
        }
    },
    extraReducers: (builder) => {
        // getClients
        builder
            .addCase(apiGetCounterParty.pending, (state, action) => {
                state.isGetCounterPartyLoading = true;
            })
            .addCase(apiGetCounterParty.fulfilled, (state, action) => {
                state.isGetCounterPartyLoading = false;
                state.counterParty = action.payload.data;
            })
            .addCase(apiGetCounterParty.rejected, (state, action) => {
                state.isGetCounterPartyLoading = false;
            });

        // create counterparty
        builder
            .addCase(apiCreateCounterParty.pending, (state, action) => {
                state.isCreateCounterPartyLoading = true;
            })
            .addCase(apiCreateCounterParty.fulfilled, (state, action) => {
                state.isCreateCounterPartyLoading = false;
            })
            .addCase(apiCreateCounterParty.rejected, (state, action) => {
                state.isCreateCounterPartyLoading = false;
            });

        // delete counterparty
        builder
            .addCase(apiDeleteCounterParty.pending, (state) => {
                state.isDeleteCounterPartyLoading = true;
            })
            .addCase(apiDeleteCounterParty.fulfilled, (state, action) => {
                state.isDeleteCounterPartyLoading = false;
            })
            .addCase(apiDeleteCounterParty.rejected, (state) => {
                state.isDeleteCounterPartyLoading = false;
            })
    }
});

export const { deleteCounterParty } = counterPartySlice.actions;

export default counterPartySlice.reducer;