import React, { useState } from 'react'
import { CommonContainer } from '../../components';
import { Box, Button, FormControl, InputLabel, OutlinedInput, Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { apiCreateHedge, apiGetHedges } from '../../store';
import { showToast } from '../../utils';
import { delivery_period_options, months_options, price_type_options, years_options } from '../../data';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../resources';

const Hedge = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { users } = useSelector((state) => state.user)
    const { portfolio } = useSelector((state) => state.portfolio)
    const { counterParty } = useSelector((state) => state.counterParty)
    const { brokers } = useSelector((state) => state.broker)
    const { endpoints } = useSelector((state) => state.endpoints)
    const { exchanges } = useSelector((state) => state.exchanges)

    const initialState = {
        instrument_type: 'forward',
        transaction_type: '',
        trade_type: '',
        trader: '',
        portfolio: '',
        counterparty: '',
        reference: '',
        trade_date: '',
        settlement_time: '',
        payment_date: '',
        accounting_date: '',
        exchange: '',
        broker: '',
        status: '',
        comments: '',
        price_type: '',
        endpoint: '',
        payment_frequency: '',
        delivery_period_sym: '',
        delivery_period_month: '',
        delivery_period_year: '',
        start_date: '',
        end_date: '',
        currency: '',
        volume: '',
        capacity: '',
    }

    const [state, setState] = useState(initialState)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const data = { ...state }
        data.trade_date = new Date(data.trade_date).toISOString()
        data.start_date = data.start_date === '' ? '' : new Date(data.start_date).toISOString()
        data.end_date = data.end_date === '' ? '' : new Date(data.end_date).toISOString()
        data.capacity = parseInt(data.capacity)
        data.volume = parseInt(data.volume)
        data.delivery_period_year = parseInt(data.delivery_period_year)

        for (const key in data) {
            if (data[key] === '' || data[key] === null || data[key] === undefined) {
                delete data[key]
            }
        }
        const response = await dispatch(apiCreateHedge(data))
        if (response.meta.requestStatus === 'fulfilled') {
            showToast('success', 'Hedge created successfully')
            setState(initialState)
            dispatch(apiGetHedges())
            navigate(PATH.HEDGES)
        } else if (response.meta.requestStatus === 'rejected') {
            showToast('error', response.payload)
        }
    }

    const handleClear = () => {
        setState(initialState)
    }

    return (
        <CommonContainer
            topic={'Create New Hedge'}
            component={'form'}
            onSubmit={handleSubmit}
        >
            <Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Transaction Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select22"
                            label="Transaction Type"
                            name='transaction_type'
                            value={state.transaction_type}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={'buy'}>Buy</MenuItem>
                            <MenuItem value={'sell'}>Sell</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Trade Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Trade Type"
                            name='trade_type'
                            value={state.trade_type}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={'Physical'}>Physical</MenuItem>
                            <MenuItem value={'Financial'}>Financial</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Trader</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Trader"
                            name='trader'
                            value={state.trader}
                            onChange={handleChange}
                        >
                            {users.map((user, index) => (
                                <MenuItem key={index} value={user}>{user.fullname}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Portfolio</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Portfolio"
                            name='portfolio'
                            value={state.portfolio}
                            onChange={handleChange}
                            required
                        >
                            {portfolio.map((pf, index) => (
                                <MenuItem key={index} value={pf}>{pf.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Counterparty</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Counterparty"
                            name='counterparty'
                            value={state.counterparty}
                            onChange={handleChange}
                        >
                            {counterParty.map((cp, index) => (
                                <MenuItem key={index} value={cp}>{cp.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth={true} >
                        <InputLabel
                            id="referenceLabel"
                        >
                            Reference
                        </InputLabel>
                        <OutlinedInput
                            id="reference"
                            label="Reference"
                            name='reference'
                            value={state.reference}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel
                            htmlFor="trade_date"
                            shrink
                            sx={{ bgcolor: 'white' }}
                        >
                            Trade Date
                        </InputLabel>
                        <OutlinedInput
                            id="trade_date"
                            type='date'
                            value={state.trade_date}
                            onChange={handleChange}
                            name='trade_date'
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Exchange</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Exchange"
                            name='exchange'
                            value={state.exchange}
                            onChange={handleChange}
                        >
                            {exchanges.map((exchange, index) => (
                                <MenuItem key={index} value={exchange}>{exchange.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Broker</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Broker"
                            name='broker'
                            value={state.broker}
                            onChange={handleChange}
                        >
                            {brokers.map((broker, index) => (
                                <MenuItem key={index} value={broker}>{broker.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Status"
                            name='status'
                            value={state.status}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={'New'}>New</MenuItem>
                            <MenuItem value={'Pending'}>Pending</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box marginBottom={2}>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="commentLabel"
                            sx={{ bgcolor: 'white' }}
                        >
                            Comments and Conditions
                        </InputLabel>
                        <OutlinedInput
                            id="comments"
                            label="Comments"
                            name='comments'
                            value={state.comments}
                            onChange={handleChange}
                        />
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Price Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Price Type"
                            name='price_type'
                            value={state.price_type}
                            onChange={handleChange}
                            required
                        >
                            {price_type_options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Endpoint</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Endpoint"
                            name='endpoint'
                            value={state.endpoint}
                            onChange={handleChange}
                        >
                            {endpoints.map((endpoint, index) => (
                                <MenuItem key={index} value={endpoint}>{endpoint.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Payment Frequency</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Payment Frequency"
                            name='payment_frequency'
                            value={state.payment_frequency}
                            onChange={handleChange}
                        >
                            <MenuItem value={'Monthly'}>Monthly</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Currency"
                            name='currency'
                            value={state.currency}
                            onChange={handleChange}
                            required
                        >
                            <MenuItem value={'EUR'}>EUR</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth>
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ bgcolor: 'white', pr: 1 }}
                        >
                            Delivery Period
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Delivery Period"
                            name='delivery_period_sym'
                            value={state.delivery_period_sym}
                            onChange={handleChange}
                            required
                        >
                            {delivery_period_options.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {state.delivery_period_sym === 'DA' || state.delivery_period_sym === 'BOM' || state.delivery_period_sym === 'MANUAL' ?
                        <FormControl fullWidth>
                            <InputLabel
                                htmlFor="start_date"
                                shrink
                                sx={{ bgcolor: 'white' }}
                            >
                                Start Date
                            </InputLabel>
                            <OutlinedInput
                                id="start_date"
                                type='date'
                                value={state.start_date}
                                onChange={handleChange}
                                name='start_date'
                                required
                            />
                        </FormControl>
                        : null
                    }
                    {state.delivery_period_sym === 'MANUAL' &&
                        <FormControl fullWidth>
                            <InputLabel
                                htmlFor="end_date"
                                shrink
                                sx={{ bgcolor: 'white' }}
                            >
                                End Date
                            </InputLabel>
                            <OutlinedInput
                                id="end_date"
                                type='date'
                                value={state.end_date}
                                onChange={handleChange}
                                name='end_date'
                                required
                            />
                        </FormControl>
                    }
                    {state.delivery_period_sym === 'MONTH' || state.delivery_period_sym === 'SUMMER' || state.delivery_period_sym === 'WINTER' || state.delivery_period_sym === 'Q1' || state.delivery_period_sym === 'Q2' || state.delivery_period_sym === 'Q3' || state.delivery_period_sym === 'Q4' || state.delivery_period_sym === 'CAL' || state.delivery_period_sym === 'GY' ?
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Month"
                                name='delivery_period_year'
                                value={state.delivery_period_year}
                                onChange={handleChange}
                                required
                            >
                                {years_options.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        : null
                    }
                    {/* show month and year if month is choosen */}
                    {state.delivery_period_sym === 'MONTH' ?
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Month</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Month"
                                name='delivery_period_month'
                                value={state.delivery_period_month}
                                onChange={handleChange}
                                required
                            >
                                {months_options.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        : null
                    }
                </Box>
                <Box className='flex gap-3' marginBottom={2}>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="capacityLabel"
                            sx={{ bgcolor: 'white', p: '0px 2px 0px' }}
                        >
                            Capacity (MW)
                        </InputLabel>
                        <OutlinedInput
                            id="capacity"
                            label="Capacity"
                            type='number'
                            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                            name='capacity'
                            value={state.capacity}
                            onChange={handleChange}
                            disabled={state.volume !== ''}
                            required
                        />
                    </FormControl>
                    <FormControl fullWidth={true} variant="outlined">
                        <InputLabel
                            id="volumeLabel"
                            sx={{ bgcolor: 'white' }}
                        >
                            Volume
                        </InputLabel>
                        <OutlinedInput
                            id="volume"
                            label="Volume"
                            type='number'
                            name='volume'
                            onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                            value={state.volume}
                            onChange={handleChange}
                            disabled={state.capacity !== ''}
                            required
                        />
                    </FormControl>
                </Box>
            </Box>
            <Box className='flex justify-end mt-2 gap-3'>
                <Button
                    variant='contained'
                    color='error'
                    onClick={handleClear}
                >
                    Clear
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={state.volume === '' && state.capacity === ''}
                >
                    Submit
                </Button>
            </Box>
        </CommonContainer>
    )
}

export default Hedge