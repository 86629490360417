import { Box, IconButton, MenuItem, Modal } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreatePortfolio from './Create'
import { ReactTable } from '../../components'
import AddBoxIcon from '@mui/icons-material/AddBox';
import GroupsIcon from '@mui/icons-material/Groups';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'
import PortfolioDetails from './PortfolioDetails'
import { apiDeletePortfolio, deletePortfolio } from '../../store'
import { showToast } from '../../utils'
import UploadMEV from './UploadMEV'
import EditUsers from './EditUsers'
import EditPortfolio from './EditPortfolio'

const Portfolio = () => {
    const dispatch = useDispatch()
    const { portfolio, isGetPortfolioLoading } = useSelector((state) => state.portfolio)
    const { users } = useSelector((state) => state.user)

    // create portfolio modal
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    const [selectedPortfolio, setSelectedPortfolio] = useState({});

    // portfolio details modal
    const [openPortfolioDetailsModal, setOpenPortfolioDetailsModal] = useState(false);
    const handleOpenPortfolioDetailsModal = (singlePortfolio) => {
        setOpenPortfolioDetailsModal(true);
        setSelectedPortfolio(singlePortfolio);
    };
    const handleClosePortfolioDetailsModal = () => { setOpenPortfolioDetailsModal(false); };

    // edit portfolio users modal
    const [openEditPortfolioUsersModal, setOpenEditPortfolioUsersModal] = useState(false);
    const handleOpenEditPortfolioUsersModal = (singlePortfolio) => {
        setOpenEditPortfolioUsersModal(true);
        setSelectedPortfolio(singlePortfolio);
    };
    const handleCloseEditPortfolioUsersModal = () => { setOpenEditPortfolioUsersModal(false); };

    // edit portfolio modal
    const [openEditPortfolioModal, setOpenEditPortfolioModal] = useState(false);
    const handleOpenEditPortfolioModal = (singlePortfolio) => {
        setOpenEditPortfolioModal(true);
        setSelectedPortfolio(singlePortfolio);
    };
    const handleCloseEditPortfolioModal = () => { setOpenEditPortfolioModal(false); };

    // upload MEV modal
    const [openUploadMEVModal, setOpenUploadMEVModal] = useState(false);
    const handleOpenUploadMEVModal = (singlePortfolio) => {
        setOpenUploadMEVModal(true);
        setSelectedPortfolio(singlePortfolio);
    };
    const handleCloseUploadMEVModal = () => { setOpenUploadMEVModal(false); };

    const handleDelete = async (portfolio) => {
        if (window.confirm("Are you sure you want to delete this portfolio?")) {
            const resp = await dispatch(apiDeletePortfolio(portfolio))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                dispatch(deletePortfolio(portfolio))
                showToast('info', 'Portfolio deleted successfully')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload)
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Profile Data",
                id: "profile",
                accessorFn: ({ profile }) => profile || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Owner",
                id: "owner",
                accessorFn: ({ owner }) => owner || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Location",
                id: "location",
                accessorFn: ({ location }) => location || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Commodity",
                id: "commodity",
                accessorFn: ({ commodity }) => commodity || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Currency",
                id: "currency",
                accessorFn: ({ currency }) => currency || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key='users' onClick={() => handleOpenEditPortfolioUsersModal(row.original)}>
                        <GroupsIcon sx={{ paddingRight: "10px" }} /> Users
                    </MenuItem>,
                    <MenuItem key='edit' onClick={() => handleOpenEditPortfolioModal(row.original)}>
                        <EditIcon sx={{ paddingRight: "10px" }} /> Edit
                    </MenuItem>,
                    <MenuItem key="upload" onClick={() => handleOpenUploadMEVModal(row.original)}>
                        <FileUploadIcon sx={{ paddingRight: "10px" }} /> Upload
                    </MenuItem>,
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original.uuid)}>
                        <DeleteIcon sx={{ paddingRight: "10px" }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreatePortfolio
                    handleClose={handleCloseModal}
                    Users={users}
                />
            </Modal>
            <Modal open={openPortfolioDetailsModal} onClose={handleClosePortfolioDetailsModal}>
                <PortfolioDetails
                    handleClose={handleClosePortfolioDetailsModal}
                    singlePortfolio={selectedPortfolio}
                />
            </Modal>
            <Modal open={openUploadMEVModal} onClose={handleCloseUploadMEVModal}>
                <UploadMEV
                    handleClose={handleCloseUploadMEVModal}
                    singlePortfolio={selectedPortfolio}
                />
            </Modal>
            <Modal open={openEditPortfolioUsersModal} onClose={handleCloseEditPortfolioUsersModal}>
                <EditUsers
                    handleClose={handleCloseEditPortfolioUsersModal}
                    singlePortfolio={selectedPortfolio}
                    Users={users}
                />
            </Modal>
            <Modal open={openEditPortfolioModal} onClose={handleCloseEditPortfolioModal}>
                <EditPortfolio
                    handleClose={handleCloseEditPortfolioModal}
                    singlePortfolio={selectedPortfolio}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <IconButton
                                onClick={handleOpenModal}
                                title="Create Portfolio"
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </div>
                    )
                }
                headerText={'Portfolio'}
                columns={columns}
                data={portfolio || []}
                actions={actionsMenu}
                handleRowClick={handleOpenPortfolioDetailsModal}
                isLoading={isGetPortfolioLoading}
            />
        </Box>
    )
}

export default Portfolio