import React from "react";
import { Sidebar } from "../components";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

const LayoutWithSidebar = () => {
    return (
        <div className="app">
            <Sidebar />
            <main className="content">
                <Box className='mt-5 mb-3 ml-10 mr-10'>
                    <Outlet />
                </Box>
            </main>
        </div>
    );
};

export default LayoutWithSidebar;