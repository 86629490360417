import React, { forwardRef, useState } from 'react'
import { Box, Stepper, Step, StepLabel, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { forecasting_report_product } from '../../data';
import { apiCreateForecast, apiGetForecasts } from '../../store'
import { useDispatch } from 'react-redux';
import { showToast } from '../../utils';

const steps = ['Choose Products', 'Select Reference'];

const CreateAutoReporting = forwardRef(({ handleClose }, ref) => {
    const dispatch = useDispatch()
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const [state, setState] = useState({
        query: '',
        ref1: '',
        ref2: '',
        ref3: '',
        ref4: 'auto'
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async () => {
        if (state.query === '' || state.ref1 === '' || state.ref2 === '' || state.ref3 === '') {
            showToast('error', 'All fields are required')
            return
        }
        const formData = new FormData()
        formData.append('query', state.query)
        formData.append('ref1', state.ref1)
        formData.append('ref2', state.ref2)
        formData.append('ref3', state.ref3)
        formData.append('ref4', state.ref4)

        const resp = await dispatch(apiCreateForecast(formData))
        if (resp.meta.requestStatus === 'fulfilled') {
            handleClose()
            showToast('info', 'Auto Reporting created successfully')
            dispatch(apiGetForecasts())
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }
    return (
        <Box
            className="modal-container"
            component="form"
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Create Auto Reporting</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {activeStep === 0 ? (<>
                        <Box className='p-4'>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                                <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5' }}>Choose a Product</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name='query'
                                    value={state.query}
                                    onChange={handleChange}
                                    required
                                >
                                    {forecasting_report_product.map((frp, index) => (
                                        <MenuItem key={frp.index} value={frp.value} >
                                            {frp.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </>
                    ) :
                        <>
                            <Box className='p-4'>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5' }}>Select Reference</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        name='ref1'
                                        value={state.ref1}
                                        onChange={handleChange}
                                        required
                                    >
                                        {forecasting_report_product.map((frp, index) => (
                                            <MenuItem key={index} value={frp.value} >
                                                {frp.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5' }}>Select Reference</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        name='ref2'
                                        value={state.ref2}
                                        onChange={handleChange}
                                        required
                                    >
                                        {forecasting_report_product.map((frp, index) => (
                                            <MenuItem key={index} value={frp.value} >
                                                {frp.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5' }}>Select Reference</InputLabel>
                                    <Select
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        name='ref3'
                                        value={state.ref3}
                                        onChange={handleChange}
                                    >
                                        {forecasting_report_product.map((frp, index) => (
                                            <MenuItem key={index} value={frp.value} >
                                                {frp.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Box>
                        </>
                    }
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}>
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </Box>

                </Box>
            </div>
        </Box>
    )
})

export default CreateAutoReporting