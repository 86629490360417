import { Box, Typography } from '@mui/material'
import React from 'react'

const CommonContainer = ({ topic, children, component, onSubmit }) => {
    return (
        <Box className='border bg-white h-full' component={component} onSubmit={onSubmit}>
            <Box className='px-8 py-4 border-b-2'>
                <Typography fontSize={'large'}>{topic}</Typography>
            </Box>
            <Box className='px-8 py-4'>
                {/* Get first children only */}
                {React.Children.map(children, (child, index) => {
                    return index === 0 ? child : null
                })}
            </Box>
            {/* Get second children only */}
            {React.Children.map(children, (child, index) => {
                return index === 1 ? <>
                    <Box className='px-8 py-4 border-t-2'>
                        {child}
                    </Box>
                </> : null
            }
            )}
        </Box>
    )
}

export default CommonContainer