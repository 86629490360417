import { Box, IconButton, MenuItem, Modal } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreateBroker from './Create'
import { ReactTable } from '../../components'
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'
import { apiDeleteBroker, deleteBroker } from '../../store'
import { showToast } from '../../utils'

const Brokers = () => {
    const dispatch = useDispatch()
    const { brokers, isGetBrokerLoading } = useSelector((state) => state.broker)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    const handleDelete = async (broker) => {
        if (window.confirm("Are you sure you want to delete this broker?")) {
            const resp = await dispatch(apiDeleteBroker(broker))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                dispatch(deleteBroker(broker))
                showToast('info', 'Endpoint deleted successfully')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload)
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Country",
                id: "country",
                accessorFn: ({ country }) => country || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Location",
                id: "location",
                accessorFn: ({ location }) => location || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original?.uuid)}>
                        <DeleteIcon sx={{ pr: '10px' }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreateBroker
                    handleClose={handleCloseModal}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <IconButton
                                onClick={handleOpenModal}
                                title="Create Exchange"
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </div>
                    )
                }
                headerText={'Brokers'}
                columns={columns}
                data={brokers || []}
                actions={actionsMenu}
                isLoading={isGetBrokerLoading}
            />
        </Box>
    )
}

export default Brokers