import React, { useMemo } from 'react'
import { CommonContainer, ReactTable } from '../../components'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'

const Statistics = ({ state }) => {
    const { supplyStatistics, isSupplyStatisticsLoading, demandStatistics, isDemandStatisticsLoading } = useSelector((state) => state.statistics);

    const month_to_int = {
        "January": 1,
        "February": 2,
        "March": 3,
        "April": 4,
        "May": 5,
        "June": 6,
        "July": 7,
        "August": 8,
        "September": 9,
        "October": 10,
        "November": 11,
        "December": 12
    }

    const supplyData = useMemo(() => {
        if (state.fyear && state.tyear) {
            let fyear = parseInt(state.fyear)
            let tyear = parseInt(state.tyear)
            let fmonth = month_to_int[state.fmonth]
            let tmonth = month_to_int[state.tmonth]
            const data = supplyStatistics?.Data?.filter((item) => {
                return (item.Year >= fyear && item.Year <= tyear && item.Month >= fmonth && item.Month <= tmonth)
            })
            return data
        }
        return supplyStatistics?.Data
    }, [supplyStatistics, state.fyear, state.tyear, state.fmonth, state.tmonth])

    const demandData = useMemo(() => {
        if (state.fyear && state.tyear) {
            let fyear = parseInt(state.fyear)
            let tyear = parseInt(state.tyear)
            let fmonth = month_to_int[state.fmonth]
            let tmonth = month_to_int[state.tmonth]
            const data = demandStatistics?.Data?.filter((item) => {
                return (item.Year >= fyear && item.Year <= tyear && item.Month >= fmonth && item.Month <= tmonth)
            })
            return data
        }
        return demandStatistics?.Data
    }, [demandStatistics, state.fyear, state.tyear, state.fmonth, state.tmonth])

    // supply statistics columns
    const supplyStatisticsColumns = useMemo(
        () => [
            {
                header: "Month",
                id: "MonthStr",
                accessorFn: ({ MonthStr }) => MonthStr || "",
                Aggregated: ({ column }) => column.id === 'MonthStr' ? 'Total' : '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50,
                Footer: () => <> Total: <span className='text-green-700'>{supplyStatistics?.TotalStr}</span> </>,
            },
            {
                header: "Year",
                id: "Year",
                accessorFn: ({ Year }) => Year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Capacity</Box>
                        <Box>[MW]</Box>
                    </Box>
                ),
                id: "Capacity",
                accessorFn: ({ Capacity }) => Capacity?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Volume</Box>
                        <Box>[MW]</Box>
                    </Box>
                ),
                id: "TotalMW",
                accessorFn: ({ TotalMW }) => TotalMW.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Volume</Box>
                        <Box>[MWh]</Box>
                    </Box>
                ),
                id: "Total",
                accessorFn: ({ Total }) => Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Price</Box>
                        <Box>[EUR/MWh]</Box>
                    </Box>
                ),
                id: "pricestr",
                Cell: ({ row }) => isNaN(row?.original?.PriceTotal / row?.original?.Total) ? "0.00" : (row?.original?.PriceTotal / row?.original?.Total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                size: 50
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Total Price</Box>
                        <Box>[EUR]</Box>
                    </Box>
                ),
                id: "PriceTotalStr",
                accessorFn: ({ PriceTotalStr }) => PriceTotalStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50
            },
        ], [supplyStatistics]
    );

    // demand statistics columns
    const demandStatisticsColumns = useMemo(
        () => [
            {
                header: "Month",
                id: "MonthStr",
                accessorFn: ({ MonthStr }) => MonthStr || "",
                Aggregated: ({ column }) => column.id === 'MonthStr' ? 'Total' : '',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50,
                Footer: () => <> Total: <span className='text-green-700'>{demandStatistics?.TotalStr}</span> </>,
            },
            {
                header: "Year",
                id: "Year",
                accessorFn: ({ Year }) => Year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 30,
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Total Volume</Box>
                        <Box>[MW]</Box>
                    </Box>
                ),
                id: "Total",
                accessorFn: ({ Total }) => Total.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 40,
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Remaining Volume</Box>
                        <Box>[MWh]</Box>
                    </Box>
                ),
                id: "RemainingVolume",
                accessorFn: ({ RemainingVolume }) => RemainingVolume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50,
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Forward Price</Box>
                        <Box>[EUR/MWh]</Box>
                    </Box>
                ),
                id: "AskPrice",
                accessorFn: ({ AskPrice }) => Math.round(AskPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 50,
            },
            {
                header: (
                    <Box display={'flex'} flexDirection={'column'} >
                        <Box>Remaining Amount</Box>
                        <Box>[EUR]</Box>
                    </Box>
                ),
                id: "PriceTotalStr",
                Cell: ({ row }) => row?.original?.PriceTotalStr ? row?.original?.PriceTotalStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : row?.original?.PriceTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                size: 50
            }
        ], [demandStatistics]
    );

    return (
        <CommonContainer topic={'Statistics'}>
            <Grid container spacing={2}>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <ReactTable
                        headerText={'Supply Statistics'}
                        columns={supplyStatisticsColumns}
                        data={supplyData || []}
                        isLoading={isSupplyStatisticsLoading}
                        renderToolbarInternalActions={
                            ({ table }) => (
                                <div onMouseDown={(e) => e.stopPropagation()}>
                                    <MRT_ToggleGlobalFilterButton table={table} />
                                    <MRT_ShowHideColumnsButton table={table} />
                                    <MRT_ToggleDensePaddingButton table={table} />
                                </div>
                            )
                        }
                    />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                    <ReactTable
                        headerText={'Demand Statistics'}
                        columns={demandStatisticsColumns}
                        data={demandData || []}
                        isLoading={isDemandStatisticsLoading}
                        renderToolbarInternalActions={
                            ({ table }) => (
                                <div onMouseDown={(e) => e.stopPropagation()}>
                                    <MRT_ToggleGlobalFilterButton table={table} />
                                    <MRT_ShowHideColumnsButton table={table} />
                                    <MRT_ToggleDensePaddingButton table={table} />
                                </div>
                            )
                        }
                    />
                </Grid>
            </Grid>
        </CommonContainer>
    )
}

export default Statistics