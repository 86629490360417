import React, { forwardRef, useState } from 'react'
import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { showToast } from '../../utils'
import { apiGetPortfolio, apiUpdatePortfolioUsers } from '../../store'
import { useDispatch } from 'react-redux'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const EditUsers = forwardRef(({ singlePortfolio, Users, handleClose }, ref) => {
    const dispatch = useDispatch()
    const [, setUsers] = useState(Users.map((user) => {
        let found = singlePortfolio.users.find((u) => u.uuid === user.uuid)
        return found ? found : user
    }))

    const [state, setState] = useState({
        usersuuid: singlePortfolio.users.map((user) => user.uuid)
    })

    const handleChangeMultiple = (event) => {
        const { target: { value }, } = event;
        setUsers(typeof value === 'string' ? value.split(',') : value)
        setState({ ...state, usersuuid: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const updatedPortfolio = {
            ...singlePortfolio,
            users: Users.filter((user) => state.usersuuid.includes(user.uuid))
        }

        const resp = await dispatch(apiUpdatePortfolioUsers(updatedPortfolio))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Users updated successfully')
            dispatch(apiGetPortfolio())
            handleClose()
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Edit Users</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="demo-multiple-name-label">Users</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={state.usersuuid}
                        onChange={handleChangeMultiple}
                        input={<OutlinedInput label="Users" />}
                        MenuProps={MenuProps}
                    >
                        {Users.map((user) => (
                            <MenuItem key={user.uuid} value={user.uuid}>
                                {user.fullname}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div >
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box >
    )
})

export default EditUsers
