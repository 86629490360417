import React from 'react'
import Profile from './Profile'
import ChangePassword from './ChangePassword'
import { Grid } from '@mui/material'

const Settings = () => {
    return (
        <Grid container spacing={3}>
            <Grid item lg={8} md={12} sm={12}>
                <Profile />
            </Grid>
            <Grid item lg={4} md={12} sm={12}>
                <ChangePassword />
            </Grid>
        </Grid>
    )
}

export default Settings