import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    exchanges: [],
    isGetExchangesLoading: false,
    isCreateExchangeLoading: false,
    isDeleteExchangeLoading: false,
}

export const apiGetExchanges = createAsyncThunk(
    "exchanges/getExchanges",
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_exchange');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreateExchange = createAsyncThunk(
    "exchange/createExchange",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_exchange', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteExchange = createAsyncThunk(
    "app/apiDeleteApp",
    async (uuid, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_exchange?uuid=${uuid}`);
            return { data: response.data, status: response.status, statusText: response.statusText };
        } catch (error) {
            const err = error.response.data;
            return thunkAPI.rejectWithValue(err);
        }
    }
);

// export const apiCreateExchanges

export const exchangeSlice = createSlice({
    name: "exchanges",
    initialState,
    reducers: {
        deleteExchange: (state, action) => {
            state.exchanges = state.exchanges.filter((exchange) => exchange.uuid !== action.payload);
        },
    },
    extraReducers: (builder) => {
        // get exchanges
        builder
            .addCase(apiGetExchanges.pending, (state, action) => {
                state.isGetExchangesLoading = true;
            })
            .addCase(apiGetExchanges.fulfilled, (state, action) => {
                state.isGetExchangesLoading = false;
                state.exchanges = action.payload.data;
            })
            .addCase(apiGetExchanges.rejected, (state, action) => {
                state.isGetExchangesLoading = false;
            });

        // createEndpoint
        builder
            .addCase(apiCreateExchange.pending, (state, action) => {
                state.isCreateExchangeLoading = true;
            })
            .addCase(apiCreateExchange.fulfilled, (state, action) => {
                state.isCreateExchangeLoading = false;
            })
            .addCase(apiCreateExchange.rejected, (state, action) => {
                state.isCreateExchangeLoading = false;
            });

        // delete endpoint
        builder
            .addCase(apiDeleteExchange.pending, (state) => {
                state.isDeleteExchangeLoading = true;
            })
            .addCase(apiDeleteExchange.fulfilled, (state, action) => {
                state.isDeleteExchangeLoading = false;
            })
            .addCase(apiDeleteExchange.rejected, (state) => {
                state.isDeleteExchangeLoading = false;
            })
    }
})

export const { deleteExchange } = exchangeSlice.actions;

export default exchangeSlice.reducer;