import { Box, IconButton, MenuItem, Modal, Typography } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactTable } from '../../components'
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'
import { apiDeleteForecast, apiGetForecasts } from '../../store'
import { showToast } from '../../utils'
import CreateAutoReporting from './Create'
import { useNavigate } from 'react-router-dom'
import { PATH } from '../../resources'

const AutoReporting = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { forecasts, isGetForecastLoading } = useSelector((state) => state.forecasts)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    const handleDelete = async (forcast) => {
        if (window.confirm("Are you sure you want to delete this Auto Report?")) {
            const resp = await dispatch(apiDeleteForecast(forcast))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                dispatch(apiGetForecasts())
                showToast('info', 'Auto Reporting deleted successfully')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload)
            }
        }
    }

    const handleGoToResult = (forecast) => {
        navigate(`${PATH.AUTO_REPORTING_RESULT}/${forecast.uuid}`)
    }

    const columns = useMemo(
        () => [
            {
                header: "UUID",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Query File",
                id: "query_file",
                accessorFn: ({ query_file }) => query_file || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Reference",
                id: "reference",
                Cell: ({ row }) => (
                    <Box className='flex flex-col'>
                        <Typography sx={{ fontSize: 'inherit' }}>{row.original?.ref1}</Typography>
                        <Typography sx={{ fontSize: 'inherit' }}>{row.original?.ref2}</Typography>
                        <Typography sx={{ fontSize: 'inherit' }}>{row.original?.ref3}</Typography>
                    </Box>
                ),
            },
            {
                header: "Date",
                id: "created_at",
                accessorFn: ({ created_at }) => new Date(created_at).toLocaleDateString() || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Status",
                id: "status",
                accessorFn: ({ status }) => status || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original?.uuid)}>
                        <DeleteIcon sx={{ pr: '10px' }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreateAutoReporting
                    handleClose={handleCloseModal}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <IconButton
                                onClick={handleOpenModal}
                                title="Create Exchange"
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </div>
                    )
                }
                headerText={'Auto Reporting'}
                columns={columns}
                data={forecasts || []}
                actions={actionsMenu}
                handleRowClick={handleGoToResult}
                isLoading={isGetForecastLoading}
            />
        </Box>
    )
}

export default AutoReporting