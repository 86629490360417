import React, { useState } from 'react'
import { CommonContainer } from '../../components'
import { Box, FormControl, InputLabel, OutlinedInput, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { apiChangePassword } from '../../store';
import { showToast } from '../../utils';
import CircularProgress from '@mui/material/CircularProgress';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const { user, isChangePasswordLoading } = useSelector((state) => state.user);

    const [state, setState] = useState({
        current: '',
        new: '',
        uuid: user.uuid,
        confirm: '',
    });

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // exclude confirm password
        const data = { ...state };
        delete data.confirm;
        const resp = await dispatch(apiChangePassword(data));
        if (resp.meta.requestStatus === 'fulfilled') {
            showToast('success', 'Password changed successfully');
            setState({
                current: '',
                new: '',
                confirm: '',
            });
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', 'Password change failed');
        } else {
            showToast('error', 'Something went wrong');
        }
    }


    return (
        <CommonContainer topic='Change Password'>
            <Box component={'form'}>
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        htmlFor="change-password-current"
                    >
                        Current Password
                    </InputLabel>
                    <OutlinedInput
                        label="Current Password"
                        id="change-password-current"
                        type="password"
                        name="current"
                        onChange={handleChange}
                        value={state.current}
                        required
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        htmlFor="change-password-new"
                    >
                        New Password
                    </InputLabel>
                    <OutlinedInput
                        label="New Password"
                        id="change-password-new"
                        type="password"
                        name="new"
                        onChange={handleChange}
                        value={state.new}
                        required
                    />
                </FormControl>
                <FormControl fullWidth={true} variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel
                        htmlFor="change-password-confirm"
                    >
                        Confirm Password
                    </InputLabel>
                    <OutlinedInput
                        label="Confirm New Password"
                        id="change-password-confirm"
                        type="password"
                        name="confirm"
                        onChange={handleChange}
                        required
                    />
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    onClick={handleSubmit}
                    // disable if new and confirm password are not same and all fields are not filled
                    disabled={state.new !== state.confirm || !state.new || !state.current || !state.confirm || isChangePasswordLoading}
                >
                    {isChangePasswordLoading ? <CircularProgress size={24} color="inherit"/> : 'Change Password'}
                </Button>
            </Box>
        </CommonContainer>
    )
}

export default ChangePassword