import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { MenuItem, Modal } from '@mui/material';
import { ReactTable } from '../../components';
import EditIcon from '@mui/icons-material/Edit';
import EditClient from './Edit';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'

const Clients = () => {
    const { clients } = useSelector(state => state.client);

    const [selectedClient, setSelectedClient] = useState({});

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (singleClient) => {
        setOpenModal(true);
        setSelectedClient(singleClient)
    };
    const handleCloseModal = () => { setOpenModal(false); };

    const columns = useMemo(() => [
        {
            header: "Name",
            id: "company_name",
            accessorFn: ({ company_name }) => company_name || "",
            Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
        },
        {
            header: "Margin",
            id: "margin",
            accessorFn: ({ margin }) => margin || "",
            Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
        }
    ], []);

    const actions = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key='edit' onClick={() => handleOpenModal(row.original)}>
                        <EditIcon sx={{ paddingRight: "10px" }} /> Edit
                    </MenuItem>
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Modal open={openModal} onClose={handleCloseModal}>
                <EditClient
                    handleClose={handleCloseModal}
                    Client={selectedClient}
                />
            </Modal>
            <ReactTable
                headerText={`Clients`}
                columns={columns || []}
                data={clients || []}
                actions={actions}
                enableRowActions
                renderRowActionMenuItems={actions}
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                        </div>
                    )
                }
            />
        </>
    )
}

export default Clients