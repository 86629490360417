import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    supplyStatistics: [],
    demandStatistics: [],
    isSupplyStatisticsLoading: false,
    isDemandStatisticsLoading: false,
};

export const apiGetSupplyStatistics = createAsyncThunk(
    "statistics/getSupplyStatistics",
    async ({ portfolioname, portfoliouuuid, fmonth, fyear, tmonth, tyear }, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_transactions_data_table?portfolioname=${portfolioname}&portfoliouuid=${portfoliouuuid}&fmonth=${fmonth}&fyear=${fyear}&tmonth=${tmonth}&tyear=${tyear}`);
            return { data: response.data, status: response.status, statusText: response.message };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiGetDemandStatistics = createAsyncThunk(
    "statistics/getDemandStatistics",
    async ({ portfolioname, portfoliouuuid, fmonth, fyear, tmonth, tyear }, thunkAPI) => {
        try {
            const response = await axios.get(`/api/get_csvprofile_data_table?portfolioname=${portfolioname}&portfoliouuid=${portfoliouuuid}&fmonth=${fmonth}&fyear=${fyear}&tmonth=${tmonth}&tyear=${tyear}`);
            return { data: response.data, status: response.status, statusText: response.message };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const statisticsSlice = createSlice({
    name: "statistics",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // getSupplyStatistics
        builder
            .addCase(apiGetSupplyStatistics.pending, (state, action) => {
                state.isSupplyStatisticsLoading = true;
            })
            .addCase(apiGetSupplyStatistics.fulfilled, (state, action) => {
                state.isSupplyStatisticsLoading = false;
                state.supplyStatistics = action.payload.data;
            })
            .addCase(apiGetSupplyStatistics.rejected, (state, action) => {
                state.isSupplyStatisticsLoading = false;
            });

        // getDemandStatistics
        builder
            .addCase(apiGetDemandStatistics.pending, (state, action) => {
                state.isDemandStatisticsLoading = true;
            })
            .addCase(apiGetDemandStatistics.fulfilled, (state, action) => {
                state.isDemandStatisticsLoading = false;
                state.demandStatistics = action.payload.data;
            })
            .addCase(apiGetDemandStatistics.rejected, (state, action) => {
                state.isDemandStatisticsLoading = false;
            });
    },
});

export default statisticsSlice.reducer;