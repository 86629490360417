import React, { useState } from 'react'
import { CommonContainer } from '../../components'
import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { apiSearch } from '../../store'
import { showToast } from '../../utils'

const SearchInput = () => {
    const dispatch = useDispatch()
    const { portfolio } = useSelector((state) => state.portfolio)

    const initialState = {
        startdate: '',
        enddate: '',
        paymentdate: '',
        instrumenttype: '',
        entrystatus: '',
        portfolio: '',
    }

    const [state, setState] = useState(initialState)

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const resp = await dispatch(apiSearch(state))
        if (resp.meta.requestStatus === 'fulfilled') {
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        } else {
            showToast('error', 'Something went wrong')
        }
    }

    const handleClear = () => {
        setState(initialState)
    }

    return (
        <CommonContainer component='form' topic={'Search'} onSubmit={handleSubmit}>
            <Box className='flex flex-col gap-4'>
                <Box className='flex gap-3'>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Portfolio</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.portfolio}
                            label="Portfolio"
                            onChange={handleChange}
                            name='portfolio'
                        >
                            {portfolio.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.uuid}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                            <MenuItem
                                value={'all'}
                            >
                                All
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Instrument Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.instrumenttype}
                            label="Instrument Type"
                            onChange={handleChange}
                            name='instrumenttype'
                        >
                            <MenuItem value={'forward'}>Forward</MenuItem>
                            <MenuItem value={'options'}>Options</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Entry Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.entrystatus}
                            label="Entry Status"
                            onChange={handleChange}
                            name='entrystatus'
                        >
                            <MenuItem value={'new'}>New</MenuItem>
                            <MenuItem value={'pending'}>Pending</MenuItem>
                            <MenuItem value={'closed'}>Closed</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box className='flex gap-3 mb-2'>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="startdate" shrink sx={{ bgcolor: 'white', p: '0 6px 0' }}>Start Date</InputLabel>
                        <OutlinedInput
                            id="startdate"
                            type='date'
                            value={state.startdate}
                            onChange={handleChange}
                            name='startdate'
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="enddate" shrink sx={{ bgcolor: 'white', p: '0 6px 0' }}>End Date</InputLabel>
                        <OutlinedInput
                            id="enddate"
                            type='date'
                            value={state.enddate}
                            onChange={handleChange}
                            name='enddate'
                        />
                    </FormControl>
                </Box>
            </Box>
            <Box className='flex justify-end mt-2 gap-3'>
                <Button
                    variant='contained'
                    color='error'
                    onClick={handleClear}
                >
                    Clear
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                >
                    Search
                </Button>
            </Box>
        </CommonContainer>
    )
}

export default SearchInput