import { Box, IconButton, MenuItem, Modal } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactTable } from '../../components'
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'
import CreateCounterParty from './Create'
import { apiDeleteCounterParty, deleteCounterParty } from '../../store'
import { showToast } from '../../utils'

const CounterParty = () => {
    const dispatch = useDispatch()
    const { counterParty, isGetCounterPartyLoading } = useSelector((state) => state.counterParty)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    const handleDelete = async (counterparty) => {
        if (window.confirm("Are you sure you want to delete this counterparty?")) {
            const resp = await dispatch(apiDeleteCounterParty(counterparty))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                dispatch(deleteCounterParty(counterparty))
                showToast('info', 'Counterparty deleted successfully')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload)
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "#",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Phone",
                id: "phone",
                accessorFn: ({ phone }) => phone || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original?.uuid)}>
                        <DeleteIcon sx={{ pr: '10px' }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreateCounterParty
                    handleClose={handleCloseModal}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <IconButton
                                onClick={handleOpenModal}
                                title="Create Exchange"
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </div>
                    )
                }
                headerText={'Counterparty'}
                columns={columns}
                data={counterParty || []}
                actions={actionsMenu}
                isLoading={isGetCounterPartyLoading}
            />
        </Box>
    )
}

export default CounterParty