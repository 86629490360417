import { MenuItem, Modal } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactTable } from '../../components';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import TransferHedge from './TransferHedge';

const HedgeTransfer = () => {
    const { pendingHedges } = useSelector(state => state.hedges)

    const [selectedRow, setSelectedRow] = useState(null)

    // create hedge transfer modal
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (hedge) => {
        setOpenModal(true);
        setSelectedRow(hedge)
    };
    const handleCloseModal = () => { setOpenModal(false); };

    const columns = useMemo(
        () => [
            {
                header: "UUID",
                id: "uuid",
                accessorFn: ({ uuid }) => uuid || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Portfolio",
                id: "portfolio",
                Cell: ({ row }) => <>{row?.original?.portfolio?.name}</>,
            },
            {
                header: "Reference",
                id: "reference",
                accessorFn: ({ reference }) => reference || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 100
            },
            {
                header: "Type",
                id: "transaction_type",
                accessorFn: ({ transaction_type }) => transaction_type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 100
            },
            {
                header: "Trade Date",
                id: "trade_date_str",
                accessorFn: ({ trade_date_str }) => trade_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 100
            },
            {
                header: "Contract",
                id: "contract",
                Cell: ({ row }) => (row?.original?.delivery_period_sym + " " + row?.original?.delivery_period_year + " " + row?.original?.delivery_period_month),
                size: 100
            },
            {
                header: "Start Date",
                id: "start_date_str",
                accessorFn: ({ start_date_str }) => start_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 100
            },
            {
                header: "End Date",
                id: "end_date_str",
                accessorFn: ({ end_date_str }) => end_date_str || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 100
            },
            {
                header: "Volume [MWh]",
                id: "volume",
                accessorFn: ({ volume }) => volume.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
                size: 100
            }
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key="tranfer" onClick={() => handleOpenModal(row.original)}>
                        <MoveUpIcon sx={{ pr: '10px' }} /> Transfer
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Modal open={openModal} onClose={handleCloseModal}>
                <TransferHedge
                    handleClose={handleCloseModal}
                    hedge={selectedRow}
                />
            </Modal>
            <ReactTable
                headerText={'Pending Hedges Transaction'}
                columns={columns}
                data={pendingHedges || []}
                actions={actionsMenu}
            />
        </>
    )
}

export default HedgeTransfer