import { Box, IconButton, MenuItem, Modal } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CreateExchange from './Create'
import { ReactTable } from '../../components'
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'
import { apiDeleteExchange, deleteExchange } from '../../store'
import { showToast } from '../../utils'

const Exchanges = () => {
    const dispatch = useDispatch()
    const { exchanges, isGetExchangesLoading } = useSelector((state) => state.exchanges)

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => { setOpenModal(true); };
    const handleCloseModal = () => { setOpenModal(false); };

    const handleDelete = async (exchange) => {
        if (window.confirm("Are you sure you want to delete this exchange?")) {
            const resp = await dispatch(apiDeleteExchange(exchange))
            if (resp?.meta?.requestStatus === 'fulfilled') {
                dispatch(deleteExchange(exchange))
                showToast('info', 'Exchange deleted successfully')
            } else if (resp?.meta?.requestStatus === 'rejected') {
                showToast('error', resp.payload)
            } else {
                showToast('error', 'Something went wrong')
            }
        }
    }

    const columns = useMemo(
        () => [
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Country",
                id: "country",
                accessorFn: ({ country }) => country || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Location",
                id: "location",
                accessorFn: ({ location }) => location || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    const actionsMenu = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key="delete" sx={{ color: 'red' }} onClick={() => handleDelete(row.original?.uuid)}>
                        <DeleteIcon sx={{ pr: '10px' }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Box>
            <Modal open={openModal} onClose={handleCloseModal}>
                <CreateExchange
                    handleClose={handleCloseModal}
                />
            </Modal>
            <ReactTable
                renderToolbarInternalActions={
                    ({ table }) => (
                        <div onMouseDown={(e) => e.stopPropagation()}>
                            <MRT_ToggleGlobalFilterButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleDensePaddingButton table={table} />
                            <IconButton
                                onClick={handleOpenModal}
                                title="Create Exchange"
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </div>
                    )
                }
                headerText={'Exchanges'}
                columns={columns}
                data={exchanges || []}
                actions={actionsMenu}
                isLoading={isGetExchangesLoading}
            />
        </Box>
    )
}

export default Exchanges