import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isCreateForwardLoading: false,
    isUpdateForwardLoading: false,
    isDeleteForwardLoading: false,
}

export const apiCreateForward = createAsyncThunk(
    "forward/createForward",
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/api/create_forward', data);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiUpdateForward = createAsyncThunk(
    "forward/updateForward",
    async ({ uuid, account_uuid, status, price, volume, transaction_type, comment }, thunkAPI) => {
        try {
            let query = "/api/update_forward?"
            if (uuid) query += `uuid=${uuid}&`
            if (account_uuid) query += `account_uuid=${account_uuid}&`
            if (status) query += `status=${status}&`
            if (price) query += `price=${price}&`
            if (volume) query += `volume=${volume}&`
            if (transaction_type) query += `transaction_type=${transaction_type}&`
            if (comment) query += `comment=${comment}`
            const response = await axios.post(query);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeleteForward = createAsyncThunk(
    "forward/deleteForward",
    async ({ uuid, account_uuid }, thunkAPI) => {
        try {
            const response = await axios.post(`/api/delete_forward?uuid=${uuid}&account_uuid=${account_uuid}`);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const forwardSlice = createSlice({
    name: "forward",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Create Forward
        builder
            .addCase(apiCreateForward.pending, (state, action) => {
                state.isCreateForwardLoading = true;
            })
            .addCase(apiCreateForward.fulfilled, (state, action) => {
                state.isCreateForwardLoading = false;
            })
            .addCase(apiCreateForward.rejected, (state, action) => {
                state.isCreateForwardLoading = false;
            })

        // Update Forward
        builder
            .addCase(apiUpdateForward.pending, (state, action) => {
                state.isUpdateForwardLoading = true;
            })
            .addCase(apiUpdateForward.fulfilled, (state, action) => {
                state.isUpdateForwardLoading = false;
            })
            .addCase(apiUpdateForward.rejected, (state, action) => {
                state.isUpdateForwardLoading = false;
            })

        // Delete Forward
        builder
            .addCase(apiDeleteForward.pending, (state, action) => {
                state.isDeleteForwardLoading = true;
            })
            .addCase(apiDeleteForward.fulfilled, (state, action) => {
                state.isDeleteForwardLoading = false;
            })
            .addCase(apiDeleteForward.rejected, (state, action) => {
                state.isDeleteForwardLoading = false;
            })
    }
});

export default forwardSlice.reducer;