import React, { forwardRef, useState } from 'react'
import { Box, Button, FormControl, Input, InputLabel, MenuItem, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { edit_portfolio_csv_type_options } from '../../data'
import { apiGetPortfolio, apiReuploadCSVData } from '../../store'
import { useDispatch } from 'react-redux'
import { showToast } from '../../utils'

const EditPortfolio = forwardRef(({ singlePortfolio, handleClose }, ref) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        data_type: '',
        demandFile: null,
        portfolio_name: singlePortfolio?.name,
        portfolio_uuid: singlePortfolio?.uuid
    })

    const handleChange = (event) => {
        const { target: { value, name }, } = event;
        setState({
            ...state,
            [name]: value,
        });
    }

    const handleFileChange = (event) => {
        setState({
            ...state,
            demandFile: event.target.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('data_type', state.data_type)
        formData.append('demandFile', state.demandFile)
        formData.append('portfolio_name', state.portfolio_name)
        formData.append('portfolio_uuid', state.portfolio_uuid)
        const resp = await dispatch(apiReuploadCSVData(formData))
        if (resp?.meta?.requestStatus === 'fulfilled') {
            showToast('success', 'Portfolio updated successfully')
            handleClose()
            dispatch(apiGetPortfolio())
        } else if (resp?.meta?.requestStatus === 'rejected') {
            showToast('error', resp.payload)
        }
    }

    return (
        <Box
            className="modal-container"
            component="form"
            onSubmit={handleSubmit}
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Reupload CSV Data</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <div className="modal-body">
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5' }}>CSV Type</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={state.data_type}
                        name='data_type'
                        onChange={handleChange}
                    >
                        {edit_portfolio_csv_type_options.map((csv_type, index) => (
                            <MenuItem key={index} value={csv_type.value} >
                                {csv_type.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="demo-multiple-name-label" sx={{ background: '#F5F5F5', display: 'none' }}>Profile CSV</InputLabel>
                    <Input
                        type='file'
                        id="demandFile"
                        name='demandFile'
                        onChange={handleFileChange}
                    />
                </FormControl>
            </div>
            <div className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type='submit'
                    className='modal-button-primary'
                >
                    Submit
                </Button>
            </div>
        </Box>
    )
})

export default EditPortfolio