import React, { forwardRef, useMemo } from 'react'
import { Box, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { MenuItem } from 'react-pro-sidebar';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactTable } from '../../components'
import {
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleGlobalFilterButton
} from 'material-react-table'

const PortfolioDetails = forwardRef(({ handleClose, singlePortfolio }, ref) => {
    // column for Effective Monthly Volume
    const columnsEMV = useMemo(
        () => [
            {
                header: "Name",
                id: "name",
                accessorFn: ({ name }) => name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Filename",
                id: "filename",
                accessorFn: ({ filename }) => filename || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Year",
                id: "year",
                accessorFn: ({ year }) => year || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Month",
                id: "month",
                accessorFn: ({ month }) => month || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
        ], []
    );

    // actions menu for Effective Monthly Volume
    const actionsMenuEMV = useMemo(
        () =>
            ({ row }) =>
                [
                    <MenuItem key="delete">
                        <DeleteIcon sx={{ pr: '10px', color: 'red' }} /> Delete
                    </MenuItem>,
                ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // column for Demand Files
    const columnsDF = useMemo(
        () => [
            {
                header: "Name",
                id: "data_type",
                accessorFn: ({ data_type }) => data_type || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            },
            {
                header: "Filename",
                id: "profile_name",
                accessorFn: ({ profile_name }) => profile_name || "",
                Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
            }
        ], []
    );

    return (
        <Box
            className="modal-container"
        >
            <Box className='flex justify-between modal-header'>
                <h2 className="modal-title">Portfolio Details</h2>
                <CloseIcon className='cursor-pointer' onClick={handleClose} />
            </Box>
            <Box className="modal-body" display={'flex'} gap={3}>
                <Box display={'flex'} flexDirection={'column'} gap={3}>
                    <Box>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography sx={{ fontWeight: 'bold' }}>Name:</Typography>
                            <Typography sx={{ fontSize: '14px' }}>{singlePortfolio.name}</Typography>
                        </Box>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography sx={{ fontWeight: 'bold' }}>Owner:</Typography>
                            <Typography sx={{ fontSize: '14px' }}>{singlePortfolio.owner}</Typography>
                        </Box>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography sx={{ fontWeight: 'bold' }}>Commodity:</Typography>
                            <Typography sx={{ fontSize: '14px' }}>{singlePortfolio.commodity}</Typography>
                        </Box>
                    </Box>
                    <ReactTable
                        renderToolbarInternalActions={
                            ({ table }) => (
                                <div onMouseDown={(e) => e.stopPropagation()}>
                                    <MRT_ToggleGlobalFilterButton table={table} />
                                    <MRT_ShowHideColumnsButton table={table} />
                                    <MRT_ToggleDensePaddingButton table={table} />
                                </div>
                            )
                        }
                        headerText={'Effective Monthly Volume'}
                        columns={columnsEMV}
                        data={singlePortfolio.mev_data || []}
                        actions={actionsMenuEMV}
                        isLoading={false}
                    />
                </Box>
                <Box display={'flex'} flexDirection={'column'} gap={3}>
                    <Box>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography sx={{ fontWeight: 'bold' }}>Profile csv:</Typography>
                            <Typography sx={{ fontSize: '14px' }}>{singlePortfolio.profile}</Typography>
                        </Box>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography sx={{ fontWeight: 'bold' }}>Location:</Typography>
                            <Typography sx={{ fontSize: '14px' }}>{singlePortfolio.location}</Typography>
                        </Box>
                        <Box display={'flex'} gap={2} alignItems={'center'}>
                            <Typography sx={{ fontWeight: 'bold' }}>Currency:</Typography>
                            <Typography sx={{ fontSize: '14px' }}>{singlePortfolio.currency}</Typography>
                        </Box>
                    </Box>
                    <ReactTable
                        renderToolbarInternalActions={
                            ({ table }) => (
                                <div onMouseDown={(e) => e.stopPropagation()}>
                                    <MRT_ToggleGlobalFilterButton table={table} />
                                    <MRT_ShowHideColumnsButton table={table} />
                                    <MRT_ToggleDensePaddingButton table={table} />
                                </div>
                            )
                        }
                        headerText={'Demand Files'}
                        columns={columnsDF}
                        data={singlePortfolio.demandfiles || []}
                        actionsMenu={actionsMenuEMV}
                        isLoading={false}
                    />
                </Box>
            </Box>
            <Box className="modal-footer">
                <Button
                    className='modal-button-secondary'
                    onClick={handleClose}
                >
                    Close
                </Button>
            </Box>
        </Box>
    )
})

export default PortfolioDetails