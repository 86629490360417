import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    posts: [],
    isPostLoading: false,
    isCreatePostLoading: false,
    isDeletePostLoading: false,
};

export const apiGetPosts = createAsyncThunk(
    "outlook/getPosts",
    async (_, thunkAPI) => {
        try {
            const response = await axios.get('/api/get_posts');
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiCreatePost = createAsyncThunk(
    "outlook/createPost",
    async (formData, thunkAPI) => {
        try {
            const response = await axios.post('/api/upload_markets_post_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const apiDeletePost = createAsyncThunk(
    "outlook/deletePost",
    async (post, thunkAPI) => {
        try {
            const response = await axios.post('/api/delete_post', post);
            return { data: response.data, status: response.status, statusText: response.message }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const outlookSlice = createSlice({
    name: "outlook",
    initialState,
    reducers: {
        deletePost: (state, action) => {
            state.posts = state.posts.filter((post) => post.uuid !== action.payload);
        },
    },
    extraReducers: (builder) => {
        // getPosts
        builder
            .addCase(apiGetPosts.pending, (state, action) => {
                state.isPostLoading = true;
            })
            .addCase(apiGetPosts.fulfilled, (state, action) => {
                state.isPostLoading = false;
                state.posts = action.payload.data;
            })
            .addCase(apiGetPosts.rejected, (state, action) => {
                state.isPostLoading = false;
            })

        // createPost
        builder
            .addCase(apiCreatePost.pending, (state, action) => {
                state.isCreatePostLoading = true;
            })
            .addCase(apiCreatePost.fulfilled, (state, action) => {
                state.isCreatePostLoading = false;
            })
            .addCase(apiCreatePost.rejected, (state, action) => {
                state.isCreatePostLoading = false;
            })

        // deletePost
        builder
            .addCase(apiDeletePost.pending, (state, action) => {
                state.isDeletePostLoading = true;
            })
            .addCase(apiDeletePost.fulfilled, (state, action) => {
                state.isDeletePostLoading = false;
            })
            .addCase(apiDeletePost.rejected, (state, action) => {
                state.isDeletePostLoading = false;
            })
    },
});

export const { deletePost } = outlookSlice.actions;

export default outlookSlice.reducer;