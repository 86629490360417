import React, { useEffect, useState } from 'react';
import ConsumptionOverallChart from './ConsumptionOverallChart';
import { useDispatch, useSelector } from 'react-redux';
import ConsumptionMonthlyChart from './ConsumptionMonthlyChart';
import Transaction from './Transaction';
import Statistics from './Statistics';
import {
    apiGetDemandStatistics,
    apiGetSupplyStatistics,
    apiGetTransaction,
    apiGetAllGraphData,
    apiGetMonthlyGraphData
} from '../../store';
import { showToast } from '../../utils';
import YearlyTotal from './YearlyTotal';
import OverallStatistics from './OverallStatistics';

// Define an array to hold the names of the months
const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const beginningYear = 2020;

const yearsList = [];

for (let i = beginningYear; i <= new Date().getFullYear(); i++) {
    yearsList.push(i);
}

const Dashboard = () => {
    const dispatch = useDispatch();

    let initialPortfolio = { uuid: '', name: '', startYear: beginningYear };
    let initialUnit = 'mw';
    let initialMonth = 0;

    // Get portfolio and consumption from the state
    const { portfolio } = useSelector((state) => state.portfolio);
    const { consumption, isGetConsumptionLoading } = useSelector(
        (state) => state.consumption
    );

    const [selectedPortfolio, setSelectedPortfolio] =
        useState(initialPortfolio);

    const [selectedYear, setSelectedYear] = useState(beginningYear);
    const [selectedMonth, setSelectedMonth] = useState(0);

    const { monthlyConsumption, isGetConsumptionMonthlyLoading } = useSelector(
        (state) => state.monthlyConsumption
    );

    // Get the selected portfolio from the local storage
    const portfolioFromLocalStorage = localStorage.getItem('selectedPortfolio');

    // Get the selected unit from the local storage
    const unitFromLocalStorage = localStorage.getItem('selectedUnit');

    // Set the selected unit
    const [selectedUnit, setSelectedUnit] = useState(
        unitFromLocalStorage ?? initialUnit
    );

    const [consumptionData, setConsumptionData] = useState([]);
    const [monthlyConsumptionGraphData, setMonthlyConsumptionGraphData] =
        useState([]);

    let monthArray = [];

    useEffect(() => {
        // The portfolio is already present in the state but takes time loading
        if (portfolio.length > 0) {
            // The portfolio from local storage can be null when the field is unchanged or the webapp is opened for the first time
            // This condition is only used on the first run
            if (portfolioFromLocalStorage === null) {
                let singlePortfolio = {
                    uuid: portfolio[0].uuid,
                    name: portfolio[0].name,
                    startYear: portfolio[0].profile_years[0]
                };

                // Used for the state of the select element
                setSelectedPortfolio(singlePortfolio);

                dispatch(apiGetAllGraphData(singlePortfolio));

                dispatch(
                    apiGetMonthlyGraphData({
                        uuid: singlePortfolio.uuid,
                        portfolioName: singlePortfolio.name,
                        year: beginningYear,
                        month: months[initialMonth],
                        monthNumber: initialMonth + 1
                    })
                );
            } else {
                const portfolioJSON = JSON.parse(portfolioFromLocalStorage);
                let singlePortfolio = {
                    uuid: portfolioJSON.uuid,
                    name: portfolioJSON.name,
                    startYear: portfolioJSON.startYear
                };

                setSelectedPortfolio(singlePortfolio);
                dispatch(apiGetAllGraphData(singlePortfolio));

                dispatch(
                    apiGetMonthlyGraphData({
                        uuid: singlePortfolio.uuid,
                        portfolioName: singlePortfolio.name,
                        year: beginningYear,
                        month: months[initialMonth],
                        monthNumber: initialMonth + 1
                    })
                );
            }
        }
    }, [portfolio]);

    if (selectedPortfolio.startYear !== 0) {
        monthArray = generateMonthList(selectedPortfolio.startYear);
    }

    useEffect(() => {
        if (selectedUnit === 'mw') {
            setConsumptionData([
                {
                    name: 'Time',
                    data: monthArray
                },
                {
                    name: 'Year',
                    data: consumption.dataMw.year || []
                },
                {
                    name: 'Month',
                    data: consumption.dataMw.month || []
                },
                {
                    name: 'Quarter',
                    data: consumption.dataMw.quarter || []
                },
                {
                    name: 'GY',
                    data: consumption.dataMw.gy || []
                },
                {
                    name: 'Others',
                    data: consumption.dataMw.others || []
                },
                {
                    name: 'Summer',
                    data: consumption.dataMw.summer || []
                },
                {
                    name: 'Winter',
                    data: consumption.dataMw.winter || []
                },
                {
                    name: 'Demand',
                    data: consumption.dataMw.demandOne || []
                },
                {
                    name: 'Demand 2',
                    data: consumption.dataMw.demandTwo || []
                },
                {
                    name: 'Demand 3',
                    data: consumption.dataMw.demandThree || []
                },
                {
                    name: 'Demand 4',
                    data: consumption.dataMw.demandFour || []
                },
                {
                    name: 'MEV',
                    data: consumption.dataMw.mev || []
                },
                {
                    name: 'Demand 5',
                    data: consumption.dataMw.demandFive || []
                }
            ]);
        } else {
            setConsumptionData([
                {
                    name: 'Time',
                    data: monthArray
                },
                {
                    name: 'Year',
                    data: consumption.data.year || []
                },

                {
                    name: 'Month',
                    data: consumption.data.month || []
                },
                {
                    name: 'Quarter',
                    data: consumption.data.quarter || []
                },
                {
                    name: 'GY',
                    data: consumption.data.gy || []
                },
                {
                    name: 'Others',
                    data: consumption.data.others || []
                },
                {
                    name: 'Summer',
                    data: consumption.data.summer || []
                },
                {
                    name: 'Winter',
                    data: consumption.data.winter || []
                },
                {
                    name: 'Demand',
                    data: consumption.data.demandOne || []
                },
                {
                    name: 'Demand 2',
                    data: consumption.data.demandTwo || []
                },
                {
                    name: 'Demand 3',
                    data: consumption.data.demandThree || []
                },
                {
                    name: 'Demand 4',
                    data: consumption.data.demandFour || []
                },
                {
                    name: 'MEV',
                    data: consumption.data.mev || []
                }
            ]);
        }
    }, [selectedUnit, consumption]);

    useEffect(() => {
        if (selectedUnit === 'mw') {
            const mev = [];
            for (
                let i = 1;
                i <= getDaysInMonth(selectedYear, selectedMonth);
                i++
            ) {
                mev.push([i, monthlyConsumption.dataMw.mev || 0]);
            }
            setMonthlyConsumptionGraphData([
                {
                    name: 'MEV',
                    data: mev
                },
                {
                    name: 'Demand',
                    data: monthlyConsumption.dataMw.profileData || []
                },
                {
                    name: 'Year',
                    data: monthlyConsumption.dataMw.year || []
                },

                {
                    name: 'Month',
                    data: monthlyConsumption.dataMw.month || []
                },
                {
                    name: 'Quarter',
                    data: monthlyConsumption.dataMw.quarter || []
                },
                {
                    name: 'GY',
                    data: monthlyConsumption.dataMw.gy || []
                },
                {
                    name: 'Others',
                    data: monthlyConsumption.dataMw.others || []
                },
                {
                    name: 'Summer',
                    data: monthlyConsumption.dataMw.summer || []
                },
                {
                    name: 'Winter',
                    data: monthlyConsumption.dataMw.winter || []
                }
            ]);
        } else {
            const mev = [];
            for (
                let i = 1;
                i <= getDaysInMonth(selectedYear, selectedMonth);
                i++
            ) {
                mev.push([i, monthlyConsumption.data.mev || 0]);
            }
            setMonthlyConsumptionGraphData([
                {
                    name: 'Demand',
                    data: monthlyConsumption.data.profileData || []
                },
                {
                    name: 'MEV',
                    data: mev
                },
                {
                    name: 'Year',
                    data: monthlyConsumption.data.year || []
                },

                {
                    name: 'Month',
                    data: monthlyConsumption.data.month || []
                },
                {
                    name: 'Quarter',
                    data: monthlyConsumption.data.quarter || []
                },
                {
                    name: 'GY',
                    data: monthlyConsumption.data.gy || []
                },
                {
                    name: 'Others',
                    data: monthlyConsumption.data.others || []
                },
                {
                    name: 'Summer',
                    data: monthlyConsumption.data.summer || []
                },
                {
                    name: 'Winter',
                    data: monthlyConsumption.data.winter || []
                }
            ]);
        }
    }, [selectedUnit, monthlyConsumption]);

    const handlePortfolioChange = (event) => {
        // Get the value from uuid
        const uuid = event.target.value;

        // Since user can select None that has empty string as value,
        // Only execute this if it's not an empty string
        if (uuid !== '') {
            // Get the single portfolio from the list of portfolio(s) using the uuid
            const extractedPortfolio = portfolio.find((p) => p.uuid === uuid);

            const singlePortfolio = {
                uuid: extractedPortfolio.uuid,
                name: extractedPortfolio.name,
                startYear: extractedPortfolio.profile_years[0]
            };

            // Save the value of selected portfolio to the localStorage
            localStorage.setItem(
                'selectedPortfolio',
                JSON.stringify(singlePortfolio)
            );

            // change the single portfolio value to be the selected portfolio value
            setSelectedPortfolio(singlePortfolio);

            // Fetch the data using the singlePortfolio's data
            dispatch(apiGetAllGraphData(singlePortfolio));

            dispatch(
                apiGetMonthlyGraphData({
                    uuid: singlePortfolio.uuid,
                    portfolioName: singlePortfolio.name,
                    year: selectedYear,
                    month: months[selectedMonth],
                    monthNumber: selectedMonth + 1
                })
            );
        }
    };

    const handleUnitChange = (event) => {
        let selectedUnit = event.target.value;
        localStorage.setItem('selectedUnit', selectedUnit);
        setSelectedUnit(selectedUnit);
    };

    const handleYearChange = (event) => {
        let selectedYear = event.target.value;
        setSelectedYear(selectedYear);
        dispatch(
            apiGetMonthlyGraphData({
                uuid: selectedPortfolio.uuid,
                portfolioName: selectedPortfolio.name,
                year: selectedYear,
                month: months[selectedMonth],
                monthNumber: selectedMonth + 1
            })
        );
    };

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        setSelectedMonth(selectedMonth);
        dispatch(
            apiGetMonthlyGraphData({
                uuid: selectedPortfolio.uuid,
                portfolioName: selectedPortfolio.name,
                year: selectedYear,
                month: months[selectedMonth],
                monthNumber: selectedMonth + 1
            })
        );
    };

    // for transactions
    const initialState = {
        fmonth: months[0],
        fyear: yearsList[0],
        tmonth: months[months.length - 1],
        tyear: yearsList[yearsList.length - 1]
    };

    const [state, setState] = useState(initialState);

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (selectedPortfolio.name !== '' && selectedPortfolio.uuid !== '') {
            dispatch(
                apiGetTransaction({
                    portfolioname: selectedPortfolio?.name,
                    portfoliouuuid: selectedPortfolio?.uuid,
                    fmonth: state.fmonth,
                    fyear: state.fyear,
                    tmonth: state.tmonth,
                    tyear: state.tyear
                })
            );
            dispatch(
                apiGetSupplyStatistics({
                    portfolioname: selectedPortfolio?.name,
                    portfoliouuuid: selectedPortfolio?.uuid,
                    fmonth: state.fmonth,
                    fyear: state.fyear,
                    tmonth: state.tmonth,
                    tyear: state.tyear
                })
            );
            dispatch(
                apiGetDemandStatistics({
                    portfolioname: selectedPortfolio?.name,
                    portfoliouuuid: selectedPortfolio?.uuid,
                    fmonth: state.fmonth,
                    fyear: state.fyear,
                    tmonth: state.tmonth,
                    tyear: state.tyear
                })
            );
        }
    }, [selectedPortfolio]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            portfolioname: selectedPortfolio?.name,
            portfoliouuuid: selectedPortfolio?.uuid,
            fmonth: state.fmonth,
            fyear: state.fyear,
            tmonth: state.tmonth,
            tyear: state.tyear
        };
        const resp = await dispatch(apiGetTransaction(data));
        if (resp.meta.requestStatus === 'fulfilled') {
        } else if (resp.meta.requestStatus === 'rejected') {
            showToast('error', resp?.payload);
        } else {
            showToast('error', 'Something went wrong');
        }

        // for supply statistics
        const resp1 = await dispatch(apiGetSupplyStatistics(data));
        if (resp1.meta.requestStatus === 'fulfilled') {
        } else if (resp1.meta.requestStatus === 'rejected') {
            showToast('error', resp1?.payload);
        } else {
            showToast('error', 'Something went wrong');
        }

        // for demand statistics
        const resp2 = await dispatch(apiGetDemandStatistics(data));
        if (resp2.meta.requestStatus === 'fulfilled') {
        } else if (resp2.meta.requestStatus === 'rejected') {
            showToast('error', resp2?.payload);
        } else {
            showToast('error', 'Something went wrong');
        }
    };

    // for supply statistics

    return (
        <div className="flex flex-col gap-8">
            <div className="w-full h-[600px] bg-white rounded p-8">
                <ConsumptionOverallChart
                    consumptionData={consumptionData}
                    isLoading={isGetConsumptionLoading}
                    portfolio={portfolio}
                    selectedPortfolio={selectedPortfolio}
                    selectedUnit={selectedUnit}
                    handlePortfolioChange={handlePortfolioChange}
                    handleUnitChange={handleUnitChange}
                />
            </div>
            <div className="w-full h-[600px] bg-white rounded p-8">
                <ConsumptionMonthlyChart
                    consumptionData={monthlyConsumptionGraphData}
                    portfolio={portfolio}
                    selectedPortfolio={selectedPortfolio}
                    selectedUnit={selectedUnit}
                    handlePortfolioChange={handlePortfolioChange}
                    handleUnitChange={handleUnitChange}
                    months={months}
                    yearsList={yearsList}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    onYearChange={handleYearChange}
                    onMonthChange={handleMonthChange}
                    monthlyConsumption={monthlyConsumptionGraphData}
                    isLoading={isGetConsumptionMonthlyLoading}
                />
            </div>
            {/* <ConsumptionMonthlyChart
                months={months}
                selectedUnit={selectedUnit}
                handleUnitChange={handleUnitChange}
                selectedPortfolio={selectedPortfolio}
                beginningYear={beginningYear}
                yearsList={yearsList}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                onYearChange={handleYearChange}
                onMonthChange={handleMonthChange}
                monthlyConsumption={monthlyConsumptionGraphData}
                isGetMonthlyConsumptionLoading={isGetConsumptionMonthlyLoading}
            /> */}
            <Transaction
                selectedPortfolio={selectedPortfolio}
                monthList={months}
                yearList={yearsList}
                state={state}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
            />
            <Statistics state={state} />
            <YearlyTotal state={state} />
            <OverallStatistics state={state} />
        </div>
    );
};

export default Dashboard;

function generateMonthList(startYear) {
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    const currentYear = new Date().getFullYear();
    const result = [];

    for (let i = startYear; i <= currentYear; i++) {
        for (let month of months) {
            result.push(`${month} ${i}`);
        }
    }

    return result;
}

function getDaysInMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
}
